import React from "react";
var FontAwesome = require("react-fontawesome");
// react-bootstrap components
import {
  Card,
  Container,
  Table,
  Row,
  Col,
  Form,
  Spinner,
  Button
} from "react-bootstrap";
import { HTTP } from '../packages/axios';
import moment from 'moment-timezone';
import { Link } from "react-router-dom";
import Pagination from 'react-bootstrap-4-pagination';
import { exportAsExcelFile } from '../utils/helper'
import { query } from "chartist";

function Customers() {

  const [data, setData] = React.useState({ users: [] });
  const [isLoading, setIsLoading] = React.useState(true);
  const [isExporting, setIsExporting] = React.useState(false);
  const [paginationConfig, setpaginationConfig] = React.useState({});
  const [whereCondition, setwhereCondition] = React.useState({});
  const [perPageLimit, setPerPageLimit] = React.useState(10);
  const [sort, setSort] = React.useState({
    name: "created_at",
    order: "desc",
    icon: "fa-sort",
  });

  const getCustomers = (query) => {
    query.where = { ...query.where, ...whereCondition, role_id: 2 };
    query.sort = sort.name && sort.order ? `[["${sort.name}","${sort.order}"]]` : "",
      query.populate = '["cus_orders"]';
    query.limit = perPageLimit;
    HTTP.get('/user', { params: query }, {
    }).then(response => {
      if (response.data) {
        setData(response.data.data);
        setIsLoading(false);
        if (response.data.data.users.length > 0) {
          setpaginationConfig({
            totalPages: Math.ceil(response.data.data.count / response.data.data.limit),
            currentPage: Math.ceil((response.data.data.limit + response.data.data.offset) / response.data.data.limit),
            showMax: 5,
            size: "sm",
            threeDots: false,
            onClick: function (e, page) {
              e.preventDefault();
              getCustomers({ offset: (page - 1) * response.data.data.limit });
            }
          })
        }
      }
    }).catch(error => {
      console.log(error);
    });
  }

  const exportExcel = (query) => {
    setIsExporting(true)
    const headers = ['#', 'CUSTOMER NAME', 'EMAIL', 'PHONE', '# BOOKINGS', 'DATE OF JOINING', 'LOGIN TYPE', 'LAST LOGIN TIME', 'STATUS'];
    query.where = { ...whereCondition, role_id: 2 };
    query.sort = sort.name && sort.order ? `[["${sort.name}","${sort.order}"]]` : "",
      query.populate = '["cus_orders"]';
    query.limit = -1;
    HTTP.get('/user', { params: query }, {
    }).then(response => {
      if (response.data) {
        if (response.data.data.users.length > 0) {
          const tables = response.data.data.users.map((_cus, index) => {
            return {
              "#": index + 1,
              "CUSTOMER NAME": _cus.full_name,
              "EMAIL": _cus.email,
              "PHONE": _cus.phone ? '(' + _cus.phone_code + ') ' + _cus.phone : '',
              "# BOOKINGS": _cus.cus_orders.length,
              "DATE OF JOINING": moment(_cus.created_at).format('MM/DD/YYYY h:mm A'),
              "LOGIN TYPE": _cus.provider == "Local" ? "Email" : "Social Login",
              "LAST LOGIN TIME": _cus.last_login_at ? moment(_cus.last_login_at).format('MM/DD/YYYY h:mm A') : "N/A",
              "STATUS": _cus.status == -5 ? "Deleted" : _cus.active == 0 ? "Blocked" : "Active",
            }
          })
          exportAsExcelFile(tables, 'Customer_Report_', headers);
          setIsExporting(false)
        }
      }
    }).catch(error => {
      setIsExporting(false)
      console.log(error);
    })

  }

  const changeDataLimit = (e) => {
    setPerPageLimit(e.target.value);
  }

  const filterData = (e) => {
    setwhereCondition({
      active: (e.target.value == 'all' || e.target.value == '-5') ? undefined : e.target.value,
      status: e.target.value == "-5" ? -5 : e.target.value == 'all' ? undefined : 0
    });
  }


  const sortCustomers = (name) => {
    setSort({
      ...sort,
      name,
      order: !sort.order ? "asc" : sort.order == "asc" ? "desc" : "",
      icon: !sort.order
        ? "fa-sort-down"
        : sort.order == "asc"
          ? "fa-sort-up"
          : "fa-sort",
    });
  };

  React.useEffect(() => {
    getCustomers({});
  }, [whereCondition, perPageLimit, sort])

  return (
    <>
      <Container fluid>
        <Row>
          <Spinner animation="border" role="status" hidden={!isLoading}>
            <span className="sr-only">Loading...</span>
          </Spinner>
          <Col md="12" hidden={!!isLoading}>
            <Card className="mb-0 table-card">
              <Card.Header className="d-flex align-items-center justify-content-between">
                <div>
                  <Card.Title as="h4">Customers</Card.Title>
                  <p className="card-category">{data.count} customers </p>
                </div>
                <div
                  className="inline-select mr-4 ml-auto"
                >
                  <Form.Control as="select" onChange={(e) => filterData(e)}>
                    <option selected disabled>Filter By Status</option>
                    <option value="all">All</option>
                    <option value="1">Active</option>
                    <option value="0">Blocked</option>
                    <option value="-5">Deleted</option>
                  </Form.Control>
                </div>
                <div
                  className="inline-select mr-4"
                >
                  <Button className="btn btn-outnile" onClick={() => exportExcel({})}>
                    <Spinner animation="border" role="status" size="sm" hidden={!isExporting} className="mr-1" />
                    {!isExporting && <FontAwesome
                      className="mr-1"
                      name="file-download"
                      size="lg"
                    />}
                    Export All
                  </Button>
                </div>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <p hidden={data.users.length > 0}>No data found</p>
                <Table className="table-hover table-bordered table-type01" hidden={data.users.length == 0}>
                  <thead>
                    <tr>
                      <th className="border-0">#</th>
                      <th className="border-0">Customer Name{" "}<i
                        className={`fas ${sort.name == "full_name" ? sort.icon : "fa-sort"
                          } text-black`}
                        onClick={() => sortCustomers("full_name")}
                        style={{ cursor: "pointer" }}
                      /></th>
                      <th className="border-0">Email{" "}<i
                        className={`fas ${sort.name == "email" ? sort.icon : "fa-sort"
                          } text-black`}
                        onClick={() => sortCustomers("email")}
                        style={{ cursor: "pointer" }}
                      /></th>
                      <th className="border-0">Phone{" "}<i
                        className={`fas ${sort.name == "phone" ? sort.icon : "fa-sort"
                          } text-black`}
                        onClick={() => sortCustomers("phone")}
                        style={{ cursor: "pointer" }}
                      /></th>
                      <th className="border-0"># Bookings</th>
                      <th className="border-0">Date of Joining{" "}<i
                        className={`fas ${sort.name == "created_at" ? sort.icon : "fa-sort"
                          } text-black`}
                        onClick={() => sortCustomers("created_at")}
                        style={{ cursor: "pointer" }}
                      /></th>
                      <th className="border-0">Login Type{" "}<i
                        className={`fas ${sort.name == "provider" ? sort.icon : "fa-sort"
                          } text-black`}
                        onClick={() => sortCustomers("provider")}
                        style={{ cursor: "pointer" }}
                      /></th>
                      <th className="border-0">Last Login Time{" "}<i
                        className={`fas ${sort.name == "last_login_at" ? sort.icon : "fa-sort"
                          } text-black`}
                        onClick={() => sortCustomers("last_login_at")}
                        style={{ cursor: "pointer" }}
                      /></th>
                      <th className="border-0">Status{" "}<i
                        className={`fas ${sort.name == "status" ? sort.icon : "fa-sort"
                          } text-black`}
                        onClick={() => sortCustomers("status")}
                        style={{ cursor: "pointer" }}
                      /></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.users.map((customer, index) => (
                      <tr key={data.offset + (index + 1)}>
                        <td>{data.offset + (index + 1)}</td>
                        <td className="font-weight-bold">
                          <Link to={"/admin/customer/profile/" + customer.id}>
                            <a className="td-user-thumb">
                              <img
                                alt={customer.full_name}
                                className="user-40"
                                src={customer.image ? customer.image : require("assets/img/faces/face-0.jpg").default}
                              ></img>
                              <p>{customer.full_name}</p>
                            </a>
                          </Link>
                        </td>
                        <td>{customer.email}</td>
                        <td>{customer.phone ? '(' + customer.phone_code + ') ' + customer.phone : ''}</td>
                        <td>{customer.cus_orders.length}</td>
                        <td>
                          {moment(customer.created_at).format('MM/DD/YYYY h:mm A')}
                        </td>
                        <td>{customer.provider == "Local" ? "Email" : "Social Login"}</td>
                        <td> {customer.last_login_at ? moment(customer.last_login_at).format('MM/DD/YYYY h:mm A') : "N/A"}</td>
                        <td>
                          {customer.active ? <>
                            {customer.status != -5 && <span className="badge badge-active">
                              <FontAwesome
                                className="mr-1"
                                name="check"
                              />
                              Active
                            </span>}
                            {customer.status == -5 && <span className="badge badge-rejected">
                              <FontAwesome
                                className="mr-1"
                                name="times"
                              />
                              Deleted
                            </span>}
                          </>
                            :
                            <span className="badge badge-pending">
                              <FontAwesome
                                className="mr-1"
                                name="times"
                              />
                              Blocked
                            </span>
                          }
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
              <div hidden={data.users.length == 0}>
                <Card.Footer className="text-center d-flex justify-content-end">
                  <Form.Group
                    controlId="exampleForm.ControlSelect1"
                    className="inline-select mr-4"
                  >
                    <Form.Label
                      style={{ minWidth: 180, textTransform: "capitalize" }}
                    >
                      Items per page
                    </Form.Label>
                    <Form.Control as="select" onChange={(e) => changeDataLimit(e)}>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                    </Form.Control>
                  </Form.Group>
                  <Pagination {...paginationConfig} />
                </Card.Footer>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Customers;
