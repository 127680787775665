import React from "react";
// react-bootstrap components
import {
  Card,
  Container,
  Row,
  Col
} from "react-bootstrap";
import {HTTP} from '../packages/axios';

function Services() {
  const [services, setServices] = React.useState({service_list: {service_categories: []}, count: 0});

  const getServices = () => {
    HTTP.get('/service/list', {}, {
    }).then(response => {
        if(response.data){
          setServices(response.data.data);
        }
    }).catch(error => {
        console.log(error);
    });
  }

  React.useEffect(() => {
    getServices();
  }, [])

  return (
    <>
      <Container fluid>
      <Row>
          <Col md="6">
            <Card className="mb-0 services-card  mb-3">
              <Card.Header className="d-flex align-items-center justify-content-between">
                <Card.Title as="h4">{services.service_list.service_categories[0] ? services.service_list.service_categories[0].category : ''}</Card.Title>
              </Card.Header>
              <Card.Body className="p-3">
                <div>
              { services.service_list.service_categories[0] ? services.service_list.service_categories[0].service_types.map((serviceType) => {
                  return (
                    <React.Fragment>
                    <h4>{serviceType.type}</h4>
                      { serviceType.services.map((service) => {
                        return (
                          <ul>
                            <li>{service.service_name}</li>
                          </ul>
                        )}) }
                    </React.Fragment>
                )}) : '' }
                </div>
              </Card.Body>
            </Card>
            <Card className="mb-0 services-card  mb-3">
              <Card.Header className="d-flex align-items-center justify-content-between">
              <Card.Title as="h4">{services.service_list.service_categories[2] ? services.service_list.service_categories[2].category : ''}</Card.Title>
              </Card.Header>
              <Card.Body className="p-3">
                <div>
              { services.service_list.service_categories[2] ? services.service_list.service_categories[2].service_types.map((serviceType) => {
                  return (
                    <React.Fragment>
                    <h4>{serviceType.type}</h4>
                      { serviceType.services.map((service) => {
                        return (
                          <ul>
                            <li>{service.service_name}</li>
                          </ul>
                        )}) }
                    </React.Fragment>
                )}) : '' }
                </div>
              </Card.Body>
            </Card>
            
            <Card className="mb-0 services-card mb-3">
              <Card.Header className="d-flex align-items-center justify-content-between">
              <Card.Title as="h4">{services.service_list.service_categories[4] ? services.service_list.service_categories[4].category : ''}</Card.Title>
              </Card.Header>
              <Card.Body className="p-3">
                <div>
              { services.service_list.service_categories[4] ? services.service_list.service_categories[4].service_types.map((serviceType) => {
                  return (
                    <React.Fragment>
                    <h4>{serviceType.type}</h4>
                      { serviceType.services.map((service) => {
                        return (
                          <ul>
                            <li>{service.service_name}</li>
                          </ul>
                        )}) }
                    </React.Fragment>
                )}) : '' }
                </div>
              </Card.Body>
            </Card>

          </Col>
          <Col md="6">
            <Card className="mb-0 services-card mb-3">
              <Card.Header className="d-flex align-items-center justify-content-between">
              <Card.Title as="h4">{services.service_list.service_categories[1] ? services.service_list.service_categories[1].category : ''}</Card.Title>
              </Card.Header>
              <Card.Body className="p-3">
                <div>
              { services.service_list.service_categories[1] ? services.service_list.service_categories[1].service_types.map((serviceType) => {
                  return (
                    <React.Fragment>
                    <h4>{serviceType.type}</h4>
                      { serviceType.services.map((service) => {
                        return (
                          <ul>
                            <li>{service.service_name}</li>
                          </ul>
                        )}) }
                    </React.Fragment>
                )}) : '' }
                </div>
              </Card.Body>
            </Card>
            <Card className="mb-0 services-card mb-3">
              <Card.Header className="d-flex align-items-center justify-content-between">
              <Card.Title as="h4">{services.service_list.service_categories[3] ? services.service_list.service_categories[3].category : ''}</Card.Title>
              </Card.Header>
              <Card.Body className="p-3">
                <div>
              { services.service_list.service_categories[3] ? services.service_list.service_categories[3].service_types.map((serviceType) => {
                  return (
                    <React.Fragment>
                    <h4>{serviceType.type}</h4>
                      { serviceType.services.map((service) => {
                        return (
                          <ul>
                            <li>{service.service_name}</li>
                          </ul>
                        )}) }
                    </React.Fragment>
                )}) : '' }
                </div>
              </Card.Body>
            </Card>

            
            <Card className="mb-0 services-card mb-3">
              <Card.Header className="d-flex align-items-center justify-content-between">
              <Card.Title as="h4">{services.service_list.service_categories[5] ? services.service_list.service_categories[5].category : ''}</Card.Title>
              </Card.Header>
              <Card.Body className="p-3">
                <div>
              { services.service_list.service_categories[5] ? services.service_list.service_categories[5].service_types.map((serviceType) => {
                  return (
                    <React.Fragment>
                    <h4>{serviceType.type}</h4>
                      { serviceType.services.map((service) => {
                        return (
                          <ul>
                            <li>{service.service_name}</li>
                          </ul>
                        )}) }
                    </React.Fragment>
                )}) : '' }
                </div>
              </Card.Body>
            </Card>

          </Col>
          <Col md="6 "></Col>
          <Col md="6 mb-3"></Col>
        </Row>
      </Container>
    </>
  );
}

export default Services;
