import React from "react";
var FontAwesome = require("react-fontawesome");
import { exportAsExcelFile } from '../utils/helper'
// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Table,
  Row,
  Col,
  Form,
  Spinner
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { HTTP } from '../packages/axios';
import moment from 'moment-timezone';
import Pagination from 'react-bootstrap-4-pagination';

function Stylists() {
  const tableRef = React.useRef(null);

  const [data, setData] = React.useState({ users: [] });
  const [isLoading, setIsLoading] = React.useState(true);
  const [isExporting, setIsExporting] = React.useState(false);
  const [paginationConfig, setpaginationConfig] = React.useState({});
  const [whereCondition, setwhereCondition] = React.useState({ status: [0] });
  const [perPageLimit, setPerPageLimit] = React.useState(10);
  const [isRequestClicked, setIsRequestClicked] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [sort, setSort] = React.useState({
    name: "created_at",
    order: "desc",
    icon: "fa-sort",
  });

  const getStylists = (query) => {
    // console.log(query);
    query.where = { ...query.where, ...whereCondition, role_id: 3 };
    query.search = search;
    query.sort = sort.name && sort.order ? `[["${sort.name}","${sort.order}"]]` : "",
      query.populate = '["orders","stripe","profile"]';
    query.limit = perPageLimit;
    HTTP.get('/user', { params: query }, {
    }).then(response => {
      if (response.data) {
        // console.log(response.data);
        setData(response.data.data);
        setIsLoading(false);
        if (response.data.data.users.length > 0) {
          setpaginationConfig({
            totalPages: Math.ceil(response.data.data.count / response.data.data.limit),
            currentPage: Math.ceil((response.data.data.limit + response.data.data.offset) / response.data.data.limit),
            showMax: 5,
            size: "sm",
            threeDots: false,
            onClick: function (e, page) {
              e.preventDefault();
              getStylists({ offset: (page - 1) * response.data.data.limit });
            }
          })
        }
      }
    }).catch(error => {
      console.log(error);
    });
  }

  const exportExcel = (query) => {
    setIsExporting(true);
    const headers = ['#', 'STYLIST NAME', 'PHONE', 'EMAIL', 'STATE', 'RATING',
      'CREATED DATE', '# BOOKINGS', 'WKLY SERVICES', 'LAST LOGIN TIME', 'BANK ACCOUNT', 'STATUS'];
    query.where = { ...whereCondition, role_id: 3 };
    query.search = search;
    query.sort = sort.name && sort.order ? `[["${sort.name}","${sort.order}"]]` : "",
      query.populate = '["orders","stripe","profile"]';
    query.limit = -1;
    HTTP.get('/user', { params: query }, {
    }).then(response => {
      if (response.data) {
        if (response.data.data.users.length > 0) {
          const tables = response.data.data.users.map((_stlx, index) => {
            return {
              "#": index + 1,
              "STYLIST NAME": _stlx.full_name,
              "PHONE": _stlx.phone ? '(' + _stlx.phone_code + ') ' + _stlx.phone : '',
              "EMAIL": _stlx.email,
              "STATE": _stlx.state ? _stlx.state : 'N/A',
              "RATING": _stlx.rating,
              "CREATED DATE": moment(_stlx.created_at).format('MM/DD/YYYY h:mm A'),
              "# BOOKINGS": _stlx.orders.length,
              "WKLY SERVICES": _stlx.frequency ? `${_stlx.frequency}` : "N/A",
              "LAST LOGIN TIME": _stlx.last_login_at ? moment(_stlx.last_login_at).format('MM/DD/YYYY h:mm A') : "N/A",
              "BANK ACCOUNT": _stlx.status != -5 ? _stlx.stripe == null ? "Not Available" : (_stlx.stripe != null && !!_stlx.stripe.active) ?
                "Active" : (_stlx.stripe != null && !_stlx.stripe.active) ? "Inactive" : "Deleted" : 'N/A',
              "STATUS": _stlx.status == -5 ? "Deleted" : _stlx.status == 0 ? "Pending" : _stlx.status == 1 ?
                "Approved" : _stlx.status == -1 ? "Rejected" : "Incomplete",
            }
          })
          exportAsExcelFile(tables, 'Stylist_Report_', headers);
          setIsExporting(false)
        }
      }
    }).catch(error => {
      setIsExporting(false)
      console.log(error);
    })
  }

  const changeDataLimit = (e) => {
    setPerPageLimit(e.target.value);
  }

  const filterData = (e, requestClicked) => {
    setIsRequestClicked(requestClicked);
    tableRef.current.scrollIntoView();
    if (!e.target.value) e.target.value = "0";
    setwhereCondition({ status: e.target.value == 'all' ? [0, 1, -2, -5] : e.target.value });
  }

  const sortStylists = (name) => {
    setSort({
      ...sort,
      name,
      order: !sort.order ? "asc" : sort.order == "asc" ? "desc" : "",
      icon: !sort.order ? "fa-sort-down" : sort.order == "asc" ? "fa-sort-up" : "fa-sort",
    });
  };

  React.useEffect(() => {
    getStylists({});
  }, [whereCondition, perPageLimit, search, sort])

  return (
    <>
      <Container fluid>
        <Spinner animation="border" role="status" hidden={!isLoading}>
          <span className="sr-only">Loading...</span>
        </Spinner>
        <div hidden={!!isLoading}>
          <Row>
            <Col md="12">
              <Card>
                <Card.Body className="d-flex justify-content-center align-items-center text-bold pb-2">
                  <div hidden={isRequestClicked || !data.pending_stylist_count}>
                    <b>
                      {data.pending_stylist_count
                        ? data.pending_stylist_count
                        : "No"}{" "}
                      New Stylist's Profile Request Is Pending
                    </b>
                    {/* <a value="0" onClick={(e) => filterData(e, true)}>
                      <Button className="btn-fill ml-2" type="button" variant="primary" >
                        See All Requests
                      </Button>
                    </a> */}
                  </div>
                  <div hidden={!isRequestClicked}>
                    <Button
                      value="all"
                      className="btn-fill ml-2"
                      type="button"
                      variant="primary"
                      onClick={(e) => filterData(e, false)}
                    >
                      View All
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className="mb-0 table-card">
                <Card.Header className="d-flex align-items-center justify-content-between">
                  <div>
                    <Card.Title as="h4">Stylists</Card.Title>
                    <p className="card-category">{data.count} stylists </p>
                  </div>
                  <div
                    className="mr-4 ml-auto text-left"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Search"
                      // minlength="6"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                  <div
                    className="inline-select mr-4"
                  >
                    <Form.Control
                      as="select"
                      onChange={(e) => filterData(e, false)}
                      value={whereCondition.status}
                    >
                      <option selected disabled>
                        Filter By
                      </option>
                      <option value="all">All</option>
                      <option value="0">Pending</option>
                      <option value="1">Approved</option>
                      <option value="-1">Rejected</option>
                      <option value="-2">Incomplete</option>
                      <option value="-5">Deleted</option>
                    </Form.Control>
                  </div>
                  <div
                    className="inline-select mr-4"
                  >
                    <Button className="btn btn-outnile" onClick={() => exportExcel({})}>
                      <Spinner animation="border" role="status" size="sm" hidden={!isExporting} className="mr-1" />
                      {!isExporting && <FontAwesome
                        className="mr-1"
                        name="file-download"
                        size="lg"
                      />}
                      Export All
                    </Button>
                  </div>
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0" style={{ overflowY: "scroll" }}>
                  <p hidden={data.users.length > 0} >No data found</p>
                  <Table
                    className="table-hover table-bordered table-type01"
                    ref={tableRef}
                    hidden={data.users.length == 0}
                  >
                    <thead>
                      <tr>
                        <th className="border-0">#</th>
                        <th className="border-0" style={{ width: "14%" }}>Stylist Name{" "}
                          <i
                            className={`fas ${sort.name == "full_name" ? sort.icon : "fa-sort"
                              } text-black`}
                            onClick={() => sortStylists("full_name")}
                            style={{ cursor: "pointer" }}
                          /></th>
                        <th className="border-0" style={{ width: "10%" }}>Phone{" "}
                          <i
                            className={`fas ${sort.name == "phone" ? sort.icon : "fa-sort"
                              } text-black`}
                            onClick={() => sortStylists("phone")}
                            style={{ cursor: "pointer" }}
                          /></th>
                        <th className="border-0">Email <i
                          className={`fas ${sort.name == "email" ? sort.icon : "fa-sort"
                            } text-black`}
                          onClick={() => sortStylists("email")}
                          style={{ cursor: "pointer" }}
                        /></th>
                        <th className="border-0" style={{ width: "7%" }}>State{" "}
                          <i
                            className={`fas ${sort.name == "state" ? sort.icon : "fa-sort"
                              } text-black`}
                            onClick={() => sortStylists("state")}
                            style={{ cursor: "pointer" }}
                          /></th>
                        <th className="border-0" style={{ width: "7%" }}>Rating</th>
                        <th className="border-0" style={{ width: "12%" }}>Created Date <i
                          className={`fas ${sort.name == "created_at" ? sort.icon : "fa-sort"
                            } text-black`}
                          onClick={() => sortStylists("created_at")}
                          style={{ cursor: "pointer" }}
                        /></th>
                        <th className="border-0"># Bookings</th>
                        <th className="border-0" style={{ width: "7%" }}>Wkly Services
                          <i
                            className={`fas ${sort.name == "frequency" ? sort.icon : "fa-sort"
                              } text-black`}
                            onClick={() => sortStylists("frequency")}
                            style={{ cursor: "pointer" }}
                          />
                        </th>
                        <th className="border-0" style={{ width: "12%" }}>Last Login Time <i
                          className={`fas ${sort.name == "last_login_at" ? sort.icon : "fa-sort"
                            } text-black`}
                          onClick={() => sortStylists("last_login_at")}
                          style={{ cursor: "pointer" }}
                        /></th>
                        <th className="border-0">Bank Account</th>
                        <th className="border-0">Status <i
                          className={`fas ${sort.name == "status" ? sort.icon : "fa-sort"
                            } text-black`}
                          onClick={() => sortStylists("status")}
                          style={{ cursor: "pointer" }}
                        /></th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.users.map((stylist, index) => (
                        <tr key={data.offset + (index + 1)}>
                          <td>{data.offset + (index + 1)}</td>
                          <td className="font-weight-bold">
                            <Link to={"/admin/stylist/profile/" + stylist.id}>
                              <a className="td-user-thumb">
                                <img
                                  alt="..."
                                  className="user-40"
                                  src={
                                    stylist.image
                                      ? stylist.image
                                      : require("assets/img/faces/face-0.jpg")
                                        .default
                                  }
                                ></img>
                                <p> {stylist.full_name} </p>
                              </a>
                            </Link>
                          </td>
                          <td>
                            {stylist.phone
                              ? "(" + stylist.phone_code + ") " + stylist.phone
                              : ""}{" "}
                            {stylist.is_phone_verified == 1 && (
                              <div className="badge badge-active-phone" >
                                <FontAwesome className="mr-1" name="check" />
                              </div>
                            )}
                          </td>
                          <td>{stylist.email}</td>
                          <td>{stylist.state ? stylist.state : 'N/A'}</td>
                          <td>
                            {" "}
                            <i className="fas fa-star text-black coloro" />{" "}
                            {stylist.rating}
                          </td>
                          <td>
                            {moment(stylist.created_at).format(
                              "MM/DD/YYYY h:mm A"
                            )}
                          </td>
                          <td>{stylist.orders.length}</td>
                          <td>{stylist.frequency ? stylist.frequency : "N/A"}</td>
                          <td> {stylist.last_login_at ? moment(stylist.last_login_at).format('MM/DD/YYYY h:mm A') : "N/A"}</td>
                          <td>
                            {stylist.status != -5 ? <> {stylist.stripe == null && (
                              <span className="badge badge-rejected">
                                <FontAwesome className="mr-1" name="times" />
                                Not available
                              </span>
                            )}

                              {stylist.stripe != null && !!stylist.stripe.active && (
                                <span className="badge badge-active">
                                  <FontAwesome className="mr-1" name="check" />
                                  Active
                                </span>
                              )}
                              {stylist.stripe != null && !stylist.stripe.active && (
                                <span className="badge badge-pending">
                                  <FontAwesome
                                    className="mr-1"
                                    name="pause-circle"
                                  />
                                  Inactive
                                </span>
                              )}</> : <span className="badge badge-rejected">
                              <FontAwesome className="mr-1" name="times" />
                              Deleted
                            </span>}
                          </td>
                          <td>
                            {stylist?.status == 0 && (
                              <span className="badge badge-pending">
                                <FontAwesome
                                  className="mr-1"
                                  name="pause-circle"
                                />
                                Pending
                              </span>
                            )}
                            {stylist?.status == -5 && (
                              <span className="badge badge-rejected">
                                <FontAwesome
                                  className="mr-1"
                                  name="times"
                                />
                                Deleted
                              </span>
                            )}
                            {stylist.status == 1 && (
                              <span className="badge badge-active">
                                <FontAwesome className="mr-1" name="check" />
                                Approved
                              </span>
                            )}
                            {stylist.status == -1 && (
                              <span className="badge badge-rejected">
                                <FontAwesome className="mr-1" name="times" />
                                Rejected
                              </span>
                            )}
                            {stylist.status == -2 && (
                              <span className="badge badge-rejected">
                                <FontAwesome className="mr-1" name="times" />
                                Incomplete
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
                <div hidden={data.users.length == 0}>
                  <Card.Footer className="text-center d-flex justify-content-end">
                    <Form.Group
                      controlId="exampleForm.ControlSelect1"
                      className="inline-select mr-4"
                    >
                      <Form.Label
                        style={{ minWidth: 180, textTransform: "capitalize" }}
                      >
                        Items per page
                      </Form.Label>
                      <Form.Control
                        as="select"
                        onChange={(e) => changeDataLimit(e)}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                      </Form.Control>
                    </Form.Group>
                    <Pagination {...paginationConfig} />
                  </Card.Footer>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}

export default Stylists;
