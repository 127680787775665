import React from "react";
var FontAwesome = require("react-fontawesome");
// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Table,
  Row,
  Col,
  Form,
  Modal,
  Spinner,
  Popover, OverlayTrigger
} from "react-bootstrap";
import { HTTP } from '../packages/axios';
import moment from 'moment-timezone';
import { notify } from 'react-notify-toast';
import Pagination from 'react-bootstrap-4-pagination';

function TextMessaging() {
  const [textMessages, setTextMessages] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [error, setError] = React.useState('')
  const [showModal, setShowModal] = React.useState(false);
  const [paginationConfig, setpaginationConfig] = React.useState({});
  const [perPageLimit, setPerPageLimit] = React.useState(10);
  const [broadcastCount, setBroadcastCount] = React.useState(0);
  const [category, setCategory] = React.useState("3");
  const [textMessage, setTextMessage] = React.useState("")
  const [title, setTitle] = React.useState('')
  const [deleteId, setDeleteId] = React.useState(null)
  const [modalDelete, setModalDelete] = React.useState(false)
  const [page, setPage] = React.useState(0)

  const sendBroadcast = (e) => {
    e.preventDefault();
    notify.hide();
    if (!textMessage.replace(/\s{2,}/g, ' ').trim()) {
      if (!textMessage.replace(/\s{2,}/g, ' ').trim()) {
        setError("*Message is required")
      }
      return
    }
    setError("")
    setIsLoading(true);
    HTTP.post(`/user/texts`, {
      user_type: category, message: textMessage.replace(/\s{2,}/g, ' ').trim()
    }).then(() => {
      getBroadcasts();
      setIsLoading(false);
      setTextMessage("")
      setCategory("3")
      setShowModal(false)
      notify.show('Successfully Sent', 'success');
    }).catch(error => {
      setIsLoading(false);
      // setShowModal(false)
      setTextMessage("")
      setCategory("3")
      notify.show('Something went wrong. Please try again.', 'error');
    });
  }

  const deleteBroadcast = (e) => {
    e.preventDefault();
    notify.hide();
    setIsDeleting(true)
    setIsLoading(true)
    HTTP.delete(`/user/texts/${deleteId}`).then(() => {
      getBroadcasts();
      setIsDeleting(false)
      setModalDelete(false)
      setDeleteId(null)
      notify.show('Successfully deleted', 'success');
    }).catch(error => {
      setDeleteId(null)
      setIsDeleting(false)
      setModalDelete(false)
      notify.show('Something went wrong. Please try again.', 'error');
    });
  }

  const getBroadcasts = (offset) => {
    let query = {};
    query.limit = perPageLimit;
    query.offset = offset;
    query.sort = '[["created_at", "DESC"]]';
    HTTP.get('/user/texts/all', { params: query }, {
    }).then(response => {
      if (response.data) {
        setTextMessages(response.data.data.texts);
        setBroadcastCount(response.data.data.count)
        setIsLoading(false);
        if (response.data.data.texts.length > 0) {
          setPage(offset)
          setpaginationConfig({
            totalPages: Math.ceil(response.data.data.count / response.data.data.limit),
            currentPage: Math.ceil((response.data.data.limit + response.data.data.offset) / response.data.data.limit),
            showMax: 5,
            size: "sm",
            threeDots: false,
            onClick: function (e, page) {
              e.preventDefault();
              getBroadcasts((page - 1) * response.data.data.limit);
            }
          })
        }
      }
    }).catch(error => {
      console.log(error);
    });
  }

  React.useEffect(() => {
    getBroadcasts();
  }, [])

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <div className="p-4">
              <Row className="mt-1 justify-content-end">
                <Button className="btn-fill" variant="primary"
                  onClick={() => { setShowModal(true) }}> <span>{showModal ?
                    <Spinner size="sm" animation="border" className="align-text-top mr-2" hidden={!showModal} />
                    : <i className="nc-icon nc-send align-text-top mr-2"></i>} {"  "} SEND MESSAGES</span></Button>
              </Row>
            </div>
          </Col>
        </Row>
        <Modal backdrop='static'
          className="modal-mini modal-primary"
          show={modalDelete}
          onHide={() => setModalDelete(false)}
        >
          <Modal.Header className="justify-content-center">
            <div className="modal-profile">
              <i className="nc-icon nc-simple-remove" style={{ color: "red" }}></i>
            </div>
          </Modal.Header>
          <Modal.Body className="text-center">
            <p>Are you sure you want to delete?</p>
          </Modal.Body>
          <div className="modal-footer">
            <Button
              className="btn-simple"
              type="button"
              variant="link"
              disabled={isDeleting}
              onClick={(e) => {
                setModalDelete(false)
                setDeleteId(null)
              }}
            >
              Close
            </Button>
            <Button
              className="btn-simple"
              type="button"
              variant="danger"
              onClick={(e) => deleteBroadcast(e)}
            >
              Continue
            </Button>
          </div>
        </Modal>
        <Modal backdrop='static'
          className="modal-normal-announcements modal-primary"
          show={showModal}
          onHide={() => setShowModal(false)}
        >
          <Modal.Header className="justify-content-center">
            <div className="modal-profile mt-3" style={{ transform: "scale(1.3)" }}>
              <i className="nc-icon nc-send" style={{ color: "#3c5fee" }}></i>
            </div>
          </Modal.Header>
          <Modal.Body className="">
            <Row>
              <Col md="12" >
                <div className="p-1">
                  <Row className="mt-1">
                    <Col md="12" >
                      <Form >
                        <h5 className="mb-3 font-weight-bold text-center">
                          Send Text Message
                        </h5>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicPassword"
                        >
                          <Form.Label>Select Audience</Form.Label>
                          <Form.Control
                            as="select"
                            onChange={
                              (e) => setCategory(e.target.value)
                            }
                            value={category}
                          >
                            <option value="3">All Stylists</option>
                            <option value="2">All Customers</option>
                          </Form.Control>
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicBody"
                        >
                          <Form.Label className={error && "error-text"}>Enter text message
                            {!textMessage && <span className="text-sm" style={{ color: "#afb5be" }}>
                              {" "} <i>max 120 characters</i></span>}
                            {!!textMessage && <span className="text-sm" style={{ color: "#afb5be" }}>
                              {" "} <i>{120 - textMessage.replace(/\s{2,}/g, ' ').length} characters left</i></span>}
                          </Form.Label>
                          <div className="text-small text-muted pl-1" style={{ fontSize: 12 }}>ISRA Invite URL (bit.ly/3LBExeh) appended upon sending</div>
                          <Form.Control as="textarea"
                            className={error && "error-border"}
                            placeholder="Enter text message"
                            minlength="6"
                            style={{ height: '100px' }}
                            maxLength="120"
                            onChange={(e) => {
                              setTextMessage(e.target.value.replace(/\s{2,}/g, ' '))
                              setError("")
                            }}
                            value={textMessage}
                            required
                          />
                          {error && <div className={error && "error-text"}>{error}</div >}
                        </Form.Group>
                      </Form>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <div className="modal-footer">
            <Button
              className="btn-fill"
              type="button"
              variant="link"
              disabled={isLoading}
              onClick={(e) => {
                setShowModal(false)
                setError("")
                setTextMessage("")
              }}
            >
              Close
            </Button>
            <Button
              className="btn-fill"
              type="button"
              variant="primary"
              disabled={isLoading}
              onClick={(e) => sendBroadcast(e)}
            ><Spinner size="sm" animation="border" hidden={!isLoading} style={{ marginBottom: "2px" }} />{" "}
              {!isLoading ? "Send Message" : "Loading"}
            </Button>
          </div>
        </Modal>
        <Row>
          <Col md="12">
            <Card className="mb-0 table-card">
              <Card.Header className="d-flex align-items-center justify-content-between">
                <div>
                  <Card.Title as="h4">Text Messaging({broadcastCount})</Card.Title>
                </div>
                <Form.Group
                  controlId="exampleForm.ControlSelect1"
                  className="inline-select mr-4"
                ></Form.Group>
              </Card.Header>
              <Card.Body hidden={textMessages.length == 0} className="table-full-width table-responsive p-0">
                <Table striped responsive className="table-hover table-bordered table-type01 mb-0">
                  <thead>
                    <tr>
                      <th style={{ width: 50 }} className="text-center"> {" "}#</th>
                      <th>Message</th>
                      <th style={{ width: 140 }}>Audience</th>
                      <th style={{ width: 170 }}>Date</th>
                      <th style={{ width: 120 }}>Action</th>
                    </tr>
                  </thead>
                  <tbody hidden={textMessages.length == 0}>
                    {textMessages.map((_text, index) => (
                      <tr key={index}>
                        <td className="text-center"> {index + 1}</td>
                        <td className="text-bold">
                          {_text.message}
                        </td>
                        <td className="font-weight-bold">
                          {_text.role_id == 3 ? "Stylists" : "Customers"}
                        </td>
                        <td>
                          {" "}
                          {moment(_text.created_at).format("MM/DD/YYYY h:mm A")}
                        </td>
                        <td className="text-center">
                          <Button className="btn-fill" variant="danger" size="sm" disabled={_text.id == deleteId || isLoading} onClick={(e) => {
                            setDeleteId(_text.id);
                            setModalDelete(true)
                          }}>{_text.id == deleteId ? <Spinner size="sm" animation="border" /> : <FontAwesome className="mr-1" name="trash" />}DELETE</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
              {textMessages.length == 0 && <div hidden={textMessages.length > 0} className="p-5 d-flex justify-content-center">
                No records found
              </div>}
              <div hidden={textMessages.length == 0}>
                <Card.Footer className="text-center d-flex justify-content-end">
                  <Pagination {...paginationConfig} />
                </Card.Footer>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default TextMessaging;
