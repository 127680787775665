import React from "react";
var FontAwesome = require("react-fontawesome");
// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Table,
  Row,
  Col,
  Form,
  Modal
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { HTTP } from '../packages/axios';
import moment from 'moment-timezone';
import { notify } from 'react-notify-toast';
import Pagination from 'react-bootstrap-4-pagination';

function CustomerProfile() {

  const [data, setData] = React.useState({ users: [] });
  const [appointments, setAppointments] = React.useState({ orders: [] });
  const [isLoading, setIsLoading] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);
  const [apptCount, setApptCount] = React.useState(0);
  const [perPageLimit, setPerPageLimit] = React.useState(10);
  const [paginationConfig, setpaginationConfig] = React.useState({});
  const { id } = useParams();

  const getCustomer = () => {
    let query = { where: { id }, populate: '["stripe"]' };
    HTTP.get('/user/find', { params: query }, {
    }).then(response => {
      if (response.data) {
        // console.log(response.data);
        setData(response.data.data);
        setIsLoading(false);
      }
    }).catch(error => {
      console.log(error);
    });
  }

  const getAppointments = (offset) => {
    let query = {
      where: { customer_id: id },
      //  upcoming_only: true 
    };
    query.limit = perPageLimit;
    query.offset = offset;
    query.sort = '[["created_at", "DESC"]]';
    HTTP.get('/order/all', { params: query }, {
    }).then(response => {
      if (response.data) {
        setAppointments(response.data.data);
        setApptCount(response.data.data.count)
        setIsLoading(false);
        console.log({ apptCount });
        if (response.data.data.orders.length > 0) {
          setpaginationConfig({
            totalPages: Math.ceil(response.data.data.count / response.data.data.limit),
            currentPage: Math.ceil((response.data.data.limit + response.data.data.offset) / response.data.data.limit),
            showMax: 5,
            size: "sm",
            threeDots: false,
            onClick: function (e, page) {
              e.preventDefault();
              getAppointments((page - 1) * response.data.data.limit);
            }
          })
        }
      }
    }).catch(error => {
      console.log(error);
    });
  }

  const blockUser = (e) => {
    e.preventDefault();
    notify.hide();
    setIsLoading(true);
    setShowModal(false);
    const active = data.user.active ? false : true;
    HTTP.put('/user/' + id, {
      active
    }).then(response => {
      setIsLoading(false);
      notify.show(active ? 'Successfully Unblocked' : 'Successfully Blocked', 'success');
      getCustomer();
    }).catch(error => {
      notify.show("Something went wrong!", 'error');
      console.log(error);
    });
  }

  React.useEffect(() => {
    getCustomer();
    getAppointments();
  }, [])

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="mb-0 mb-3 d-flex p-0">
              <Card.Header className="d-flex align-items-center justify-content-between border-bottom-1">
                <div className="d-flex align-items-center w-100 pb-3 justify-content-between">
                  <div className="d-flex align-items-center">
                    <div>
                      <img
                        alt="..."
                        className="avatar-100 mr-4"
                        src={data.user && data.user.image ? data.user.image : require("assets/img/faces/face-0.jpg").default}
                      ></img>
                    </div>
                    <div>
                      <div className="d-flex align-items-center">
                        <span className={data.user && data.user.active ? data.user.status == -5 ? 'badge badge-rejected mr-2' : 'badge badge-active mr-2' : 'badge badge-warning mr-2'}>
                          <FontAwesome
                            className="mr-1"
                            name={data.user && data.user.active ? data.user.status == -5 ? 'times' : 'check' : 'times'}
                          />
                          {data.user && data.user.active ? data.user.status == -5 ? 'Deleted' : 'Active' : 'Blocked'}
                        </span>
                        <span className="font-weight-bold text-secondary">
                          {data.user ? '#ISRA' + data.user.id : ''}
                        </span>
                      </div>
                      <h3 className="my-2">{data.user ? data.user.full_name : ''}</h3>
                      <div className="d-flex justify-content-center">
                        <div className="border-right pr-2">{data.user && data.user.phone ? '(' + data.user.phone_code + ') ' + data.user.phone : ''}</div>
                        <div className="px-2">{data.user ? data.user.email : ''}</div>
                      </div>
                    </div>
                  </div>
                  <div className="mx-auto">
                    <p className="mb-1 font-weight-bold text-muted">
                      Active from
                    </p>
                    <p className="mb-0">{data.user ? moment(data.user.created_at).format('MM/DD/YYYY') : ''}</p>
                  </div>
                  <div className="mx-auto">
                    <p className="mb-1 font-weight-bold text-muted">
                      Stripe ID
                    </p>
                    <p className="mb-0">{data.user && data.user.stripe ? data.user.stripe.stripe_id : 'N/A'}</p>
                  </div>
                  <div className="ml-auto d-flex">
                    {data.user && data.user.status != -5 && <> {
                      data.user && data.user.active ?
                        <Button
                          className="btn-outline btn-danger mr-2 btn-block d-flex align-items-center justify-content-center"
                          type="submit"
                          variant="info"
                          onClick={(e) => setShowModal(true)}
                        >
                          <FontAwesome
                            className="mr-1"
                            name="ban"
                            size="sm"
                          />
                          Block
                        </Button>
                        :
                        <Button
                          className="btn-outline btn-danger mr-2 btn-block d-flex align-items-center justify-content-center"
                          type="submit"
                          variant="info"
                          onClick={(e) => setShowModal(true)}
                        >
                          <FontAwesome
                            className="mr-1"
                            name="ban"
                            size="sm"
                          />
                          Unblock
                        </Button>
                    }</>}
                    <Modal
                      className="modal-mini modal-primary"
                      show={showModal}
                      onHide={() => setShowModal(false)}
                    >
                      <Modal.Header className="justify-content-center">
                        <div className="modal-profile">
                          <i className="nc-icon nc-bulb-63"></i>
                        </div>
                      </Modal.Header>
                      <Modal.Body className="text-center">
                        <p>Are you sure, Do you want to continue?</p>
                      </Modal.Body>
                      <div className="modal-footer">
                        <Button
                          className="btn-simple"
                          type="button"
                          variant="link"
                          onClick={() => setShowModal(false)}
                        >
                          Close
                        </Button>
                        <Button
                          className="btn-simple"
                          type="button"
                          variant="danger"
                          onClick={(e) => blockUser(e)}
                        >
                          Continue
                        </Button>
                      </div>
                    </Modal>
                    <Link to="/admin/customers">
                      <Button className="btn btn-outnile d-flex align-items-center justify-content-center">
                        <FontAwesome
                          className="mr-1"
                          name="arrow-left"
                          size="sm"
                        />
                        Back
                      </Button>
                    </Link>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className="p-0 border-0 m-0">
                <Card className="mb-0 p-0 table-card border-left-0 border-right-0">
                  <Card.Header className="d-flex align-items-center">
                    <div>
                      <Card.Title as="h4">Hair Style Changer</Card.Title>
                    </div>
                  </Card.Header>
                  <Card.Body className="table-full-width table-responsive p-0 mb-0 border-0"
                    style={{ background: "#0e518d", color: "white" }}>
                    <div className="ml-3 p-3">
                      Available Credits: <span style={{ fontWeight: "bold" }}>{data.credits || 0}</span>
                    </div>
                  </Card.Body>
                </Card>
                <Card className="mb-0 p-0 table-card border-left-0 border-right-0">
                  <Card.Header className="d-flex align-items-center">
                    <div>
                      <Card.Title as="h4">Appointments ({apptCount})</Card.Title>
                    </div>
                  </Card.Header>
                  <Card.Body className="table-full-width table-responsive p-0 mb-0 border-0">
                    <Table className="table-hover table-bordered table-type01 border-left-0 m-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Booking ID</th>
                          <th>Amount</th>
                          <th>Stylist Details</th>
                          <th>Service Type</th>
                          <th>Booked on</th>
                          <th>Service Status</th>
                        </tr>
                      </thead>
                      <p hidden={appointments.orders.length > 0}>No records found</p>
                      <tbody hidden={appointments.orders.length == 0}>
                        {appointments.orders.map((order, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              <Link to={"/admin/appointment/" + order.id}>#ISRA{order.id}</Link>
                            </td>
                            <td className="text-bold">{'$' + parseFloat(order.order_amount).toLocaleString()}</td>
                            <td className="font-weight-bold">
                              <a className="td-user-thumb">
                                <img
                                  alt="..."
                                  className="user-40"
                                  src={order.provider.image ? order.provider.image : require("assets/img/faces/face-0.jpg").default}
                                ></img>
                                <p> {order.provider.full_name} </p>
                              </a>
                            </td>

                            <td>
                              <div className="service-icon">
                                {order.service_location == "MOBILE" ?
                                  <React.Fragment>
                                    <span className="at-home"></span>Home
                                  </React.Fragment>
                                  :
                                  <React.Fragment>
                                    <span className="at-salon"></span>Salon
                                  </React.Fragment>
                                }
                              </div>
                            </td>
                            <td>
                              {" "}
                              {moment(order.created_at).format('MM/DD/YYYY h:mm A')}
                            </td>
                            <td>
                              {order.order_status == 'CANCELLED' ?
                                <span className="badge badge-pending" title={order.remarks}>
                                  <FontAwesome
                                    className="mr-1"
                                    name="times"
                                  />
                                  {order.order_status.charAt(0) + order.order_status.slice(1).toLowerCase().replace('_', ' ')}
                                </span>
                                :
                                <span className="badge badge-warning badge-active">
                                  <FontAwesome
                                    className="mr-1"
                                    name="check"
                                    size="sm"
                                  />
                                  {order.order_status.charAt(0) + order.order_status.slice(1).toLowerCase().replace('_', ' ')}
                                </span>
                              }
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Card.Body>
              <div hidden={appointments.orders.length == 0}>
                <Card.Footer className="text-center d-flex justify-content-end">
                  <Pagination {...paginationConfig} />
                </Card.Footer>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CustomerProfile;
