/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { useLocation } from "react-router-dom";
import { Navbar, Container, Nav, Dropdown, Button } from "react-bootstrap";
var FontAwesome = require("react-fontawesome");
import {sidebarRoutes, dashboardRoutes} from "routes.js";
import { Link } from "react-router-dom";
import {HTTP} from '../../packages/axios';

function Header() {
  const [notificationData, setNotificationData] = React.useState({notifications: [], count: 0});

  const location = useLocation();
  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    
    document.body.appendChild(node);
  };

  const getBrandText = () => {
    let concatedRoutes = sidebarRoutes.concat(dashboardRoutes);
    for (let i = 0; i < concatedRoutes.length; i++) {
      if (location.pathname.indexOf(concatedRoutes[i].layout + concatedRoutes[i].path) !== -1) {
        return concatedRoutes[i].name;
      }
    }
    return "ISRA";
  };

  const getNotifications = (query) => {
    query.where = {seen: false};
    HTTP.get('/user/notifications', {params: query}, {
    }).then(response => {
        if(response.data){
          setNotificationData(response.data.data);
        }
    }).catch(error => {
        console.log(error);
    });
  }

  React.useEffect(() => {
    getNotifications({});
  }, [])

  return (
    <Navbar bg="white" expand="lg">
      <Container fluid>
        <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
          <Button
            variant="dark"
            className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle p-2"
            onClick={mobileSidebarToggle}
          >
            <i className="fas fa-ellipsis-v"></i>
          </Button>
          <Navbar.Brand
            href="#"
            onClick={(e) => e.preventDefault()}
            className="mr-2"
          >
            {getBrandText()}
          </Navbar.Brand>
        </div>
        <div className="admin_user">
        <Link to={"/admin/notifications"} onClick={(e) => { e.preventDefault; getNotifications({seen: true}) }}><span className="badge badge-danger">{notificationData.count ? notificationData.count : ''}</span><FontAwesome name="bell" size="lg" className="notify" /></Link>
          <img
            alt="..."
            className="avatar border-gray"
            src={require("assets/img/faces/face-0.jpg").default}
          ></img>
          <span className="d-none d-sm-block">Administrator</span>
        </div>
      </Container>
    </Navbar>
  );
}

export default Header;
