import React from "react";
var FontAwesome = require("react-fontawesome");
// react-bootstrap components
import {
  Card,
  Container,
  Table,
  Row,
  Col,
  Form,
  Spinner,
  Button,
  Modal
} from "react-bootstrap";
import { HTTP } from '../packages/axios';
import moment from 'moment-timezone';
import Pagination from 'react-bootstrap-4-pagination';
import { notify } from 'react-notify-toast';
import { Link } from "react-router-dom";

function Payments() {
  const [payments, setPayments] = React.useState({ transactions: [] });
  const [isLoading, setIsLoading] = React.useState(true);
  const [paginationConfig, setpaginationConfig] = React.useState({});
  const [whereCondition, setwhereCondition] = React.useState({});
  const [showModal, setShowModal] = React.useState(false);
  const [refundId, setRefundId] = React.useState(null);
  const [perPageLimit, setPerPageLimit] = React.useState(10);

  const getPayments = (query) => {
    query.where = { ...query.where, ...whereCondition };
    query.sort = '[["created_at", "DESC"]]';
    query.limit = perPageLimit;
    HTTP.get('/payments/all', { params: query }, {
    }).then(response => {
      if (response.data) {
        setPayments(response.data.data);
        setIsLoading(false);
        if (response.data.data.transactions.length > 0) {
          setpaginationConfig({
            totalPages: Math.ceil(response.data.data.count / response.data.data.limit),
            currentPage: Math.ceil((response.data.data.limit + response.data.data.offset) / response.data.data.limit),
            showMax: 5,
            size: "sm",
            threeDots: false,
            onClick: function (e, page) {
              e.preventDefault();
              getPayments({ offset: (page - 1) * response.data.data.limit });
            }
          })
        }
      }
    }).catch(error => {
      console.log(error);
    });
  }

  const changeDataLimit = (e) => {
    setPerPageLimit(e.target.value);
  }

  const filterData = (e) => {
    if (e.target.value == 'all') {
      setwhereCondition({});
    } else {
      setwhereCondition(!e.target.value ? { refund_id: null } : { refund_id: { "$ne": null } });
    }
  }

  const setRefundModal = (e, id) => {
    e.preventDefault();
    setRefundId(id);
    setShowModal(true);
  }

  const refundPayment = (e) => {
    e.preventDefault();
    notify.hide();
    setIsLoading(true);
    setShowModal(false);
    HTTP.post('/payments/refund/' + refundId, {
    }).then(response => {
      setIsLoading(false);
      notify.show('Successfully refunded', 'success');
      getPayments({});
    }).catch(error => {
      setIsLoading(false);
      notify.show("Something went wrong!", 'error');
      console.log(error);
    });
  }

  React.useEffect(() => {
    getPayments({});
  }, [whereCondition, perPageLimit])

  return (
    <>
      <Container fluid>
        <Spinner animation="border" role="status" hidden={!isLoading}>
          <span className="sr-only">Loading...</span>
        </Spinner>
        <div hidden={!!isLoading}>
          <Row>
            <Col md="12">
              <Card className="mb-0 table-card">
                <Card.Header className="d-flex align-items-center justify-content-between">
                  <div>
                    <Card.Title as="h4">Payment</Card.Title>
                    <p className="card-category">You have {payments.count} transactions</p>
                  </div>
                  <Form.Group
                    controlId="exampleForm.ControlSelect1"
                    className="inline-select mr-4"
                  >
                    <Form.Control as="select" onChange={(e) => filterData(e)} value={whereCondition.refund_id ? 'refunded' : Object.keys(whereCondition).length == 0 ? 'all' : ''}>
                      <option selected disabled>Filter By</option>
                      <option value="all">All</option>
                      <option value="">Done</option>
                      <option value="refunded">Refunded</option>
                    </Form.Control>
                  </Form.Group>
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0">
                  <Table className="table-hover table-bordered table-type01">
                    <thead>
                      <tr>
                        <th className="border-0">#</th>
                        <th className="border-0">Transaction ID</th>
                        <th className="border-0">Booking ID</th>
                        <th className="border-0">Amount</th>
                        <th className="border-0">Customer Details</th>
                        <th className="border-0">Stylist Details</th>
                        <th className="border-0">Service Type</th>
                        <th className="border-0">Booked on</th>
                        <th className="border-0">Status</th>
                      </tr>
                    </thead>
                    <p hidden={payments.transactions.length > 0}>No records found</p>
                    <tbody hidden={payments.transactions.length == 0}>
                      {payments.transactions.map((transaction, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <a>{transaction.transaction_id}</a>
                          </td>
                          <td>
                            <a><Link to={"/admin/appointment/" + transaction.order.id}>#{transaction.order.id}</Link></a>
                          </td>
                          <td className="text-bold">{'$' + parseFloat(transaction.collected_amount).toLocaleString()}</td>
                          <td className="font-weight-bold">
                            <a className="td-user-thumb">
                              <img
                                alt="..."
                                className="user-40"
                                src={transaction.order.customer.image ? transaction.order.customer.image : require("assets/img/faces/face-0.jpg").default}
                              ></img>
                              <p> <Link to={"/admin/customer/profile/" + transaction.order.customer.id}>{transaction.order.customer.full_name}</Link> </p>
                            </a>
                          </td>

                          <td className="font-weight-bold">
                            <a className="td-user-thumb">
                              <img
                                alt="..."
                                className="user-40"
                                src={transaction.order.provider.image ? transaction.order.provider.image : require("assets/img/faces/face-0.jpg").default}
                              ></img>
                              <p> <Link to={"/admin/stylist/profile/" + transaction.order.provider.id}>{transaction.order.provider.full_name}</Link> </p>
                            </a>
                          </td>

                          <td>
                            <div className="service-icon">
                              {transaction.order.service_location == "MOBILE" ?
                                <React.Fragment>
                                  <span className="at-home"></span>Home
                                </React.Fragment>
                                :
                                <React.Fragment>
                                  <span className="at-salon"></span>Salon
                                </React.Fragment>
                              }
                            </div>
                          </td>
                          <td>
                            {" "}
                            {moment(transaction.order.created_at).format('MM/DD/YYYY h:mm A')}
                          </td>
                          <td>
                            {!transaction.refund_id ?
                              <span className="badge badge-active">
                                <FontAwesome
                                  className="mr-1"
                                  name="check"
                                  size="sm"
                                />
                                Done
                              </span>
                              :
                              <span className="badge badge-pending">
                                <FontAwesome
                                  className="mr-1"
                                  name="times"
                                />
                                Refunded
                              </span>
                            }
                          </td>
                          <td>
                            <Button
                              hidden={transaction.refund_id}
                              className="btn"
                              type="submit"
                              variant="danger"
                              onClick={(e) => setRefundModal(e, transaction.id)}
                            >
                              <FontAwesome
                                className="mr-1"
                                name="times-circle"
                                size="sm"
                              />
                              Refund
                            </Button>
                          </td>
                          <Modal
                            className="modal-mini modal-primary"
                            show={showModal}
                            onHide={() => setShowModal(false)}
                          >
                            <Modal.Header className="justify-content-center">
                              <div className="modal-profile">
                                <i className="nc-icon nc-bulb-63"></i>
                              </div>
                            </Modal.Header>
                            <Modal.Body className="text-center">
                              <p>Are you sure, Do you want to continue?</p>
                            </Modal.Body>
                            <div className="modal-footer">
                              <Button
                                className="btn-simple"
                                type="button"
                                variant="link"
                                onClick={(e) => setShowModal(false)}
                              >
                                Close
                              </Button>
                              <Button
                                className="btn-simple"
                                type="button"
                                variant="danger"
                                onClick={(e) => refundPayment(e)}
                              >
                                Continue
                              </Button>
                            </div>
                          </Modal>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
                <div hidden={payments.transactions.length == 0}>
                  <Card.Footer className="text-center d-flex justify-content-end">
                    <Form.Group
                      controlId="exampleForm.ControlSelect1"
                      className="inline-select mr-4"
                    >
                      <Form.Label
                        style={{ minWidth: 180, textTransform: "capitalize" }}
                      >
                        Items per page
                      </Form.Label>
                      <Form.Control as="select" onChange={(e) => changeDataLimit(e)}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                      </Form.Control>
                    </Form.Group>
                    <Pagination {...paginationConfig} />
                  </Card.Footer>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}

export default Payments;
