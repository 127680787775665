/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useLocation, NavLink } from "react-router-dom";
import {history} from '../../routes';
import {HTTP} from '../../packages/axios';

import { Nav, Modal, Button } from "react-bootstrap";

function logout(e){
  e.preventDefault();
  HTTP.post("/auth/logout", {})
  .then(response => {
      localStorage.removeItem('isra_access_token');
      history.push('/');
  })
  .catch(err => {
      console.log(err);
  });
}

function Sidebar({ color, image, routes }) {
  const [showModal, setShowModal] = React.useState(false);

  const location = useLocation();
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  return (
    <div className="sidebar" data-color={color}>
      <div className="sidebar-wrapper">
        <div className="logo d-flex align-items-center justify-content-center">
          <a href="#" className="simple-text logo-mini mx-1">
            <div className="logo-img">
              <img
                src={require("assets/img/logo_text.png").default}
                alt="..."
              />
            </div>
          </a>
        </div>
        <Nav>
          {routes.map((prop, key) => {
            if (!prop.redirect)
              return (
                <li
                  className={
                    prop.upgrade
                      ? "active active-pro"
                      : activeRoute(prop.layout + prop.path)
                  }
                  key={key}
                >
                  <NavLink
                    to={prop.layout + prop.path}
                    className="nav-link"
                    activeClassName="active"
                  >
                    <p>{prop.name}</p>
                    <i class="fas fa-chevron-right"></i>
                  </NavLink>
                </li>
              );
            return null;
          })}
          <li>
            <NavLink
              to="#"
              onClick={() => setShowModal(true)}
              className="nav-link"
              activeClassName="active"
            >
              <p>Logout</p>
            </NavLink>
          </li>
        </Nav>
          <Modal
            className="modal-mini modal-primary"
            show={showModal}
            onHide={() => setShowModal(false)}
          >
            <Modal.Header className="justify-content-center">
              <div className="modal-profile">
                <i className="nc-icon nc-bulb-63"></i>
              </div>
            </Modal.Header>
            <Modal.Body className="text-center">
              <p>Are you sure, Do you want to logout?</p>
            </Modal.Body>
            <div className="modal-footer">
              <Button
                className="btn-simple"
                type="button"
                variant="link"
                onClick={() => setShowModal(false)}
              >
                Close
              </Button>
              <Button
                className="btn-simple"
                type="button"
                variant="danger"
                onClick={(e) => logout(e) }
              >
                Logout
              </Button>
            </div>
          </Modal>
      </div>
    </div>
  );
}

export default Sidebar;
