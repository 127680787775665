import React, {Component} from "react";
import {HTTP} from '../packages/axios';
import {notify} from 'react-notify-toast';

// react-bootstrap components
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card
} from "react-bootstrap";

export default class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
        email: "",
        password: "",
        isLoading: false
    }
  }

  handleInputChange = (event) => {
    const inputState = event.target.name;
    const inputValue = event.target.value;
    this.setState({ [inputState]: inputValue });
}

handleSubmit = (e) => {
    e.preventDefault();
    this.setState({isLoading: true});
    const {email, password} = this.state;
    HTTP.post("/auth/local", {username: email, password})
    .then(response => {;
      if(response.data.data.user.role_id === 1){
        this.setState({isLoading: false});
        this.props.history.push('/admin/dashboard');
      }else{
        localStorage.removeItem('isra_access_token');
        notify.show("Incorrect username or password", 'error');
        this.setState({isLoading: false});
      }
    })
    .catch(err => {
        notify.show("Incorrect username or password", 'error');
        this.setState({isLoading: false});
        console.log(err);
    });
};

render() {
  return(
    <Container
        fluid
        className="d-flex align-items-center justify-content-center flex-column login-bg"
      >
        <Col md="12">
          <div className="logo-img text-center mb-4">
            <img src={require("assets/img/logo_text.png").default} alt="..." />
          </div>
        </Col>
        <Col md="12">
          <Card className="login-wrapper">
            <Card.Body className="pb-5 pt-3">
              <Row className="justify-content-md-center">
                <Col xs="10">
                  <h4>Login</h4>
                </Col>
              </Row>
              <Form onSubmit={(e) => this.handleSubmit(e)}>
                <Row className="justify-content-md-center">
                  <Col xs="10">
                    <Form.Group className="mb-0">
                      <Form.Control
                        placeholder="Email"
                        type="email"
                        name="email"
                        onChange={ this.handleInputChange } 
                        value={ this.state.email }
                        required
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="justify-content-md-center">
                  <Col xs="10">
                    <Form.Group>
                      <Form.Control
                        placeholder="Password"
                        type="password"
                        name="password"
                        onChange={ this.handleInputChange } 
                        value={ this.state.password }
                        required
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col xs="10">
                    { !this.state.isLoading ? 
                        <Button className="btn btn-fill btn-block" type="submit">Login</Button>
                        :
                        <Button className="btn btn-fill btn-block" disabled type="button">Loading...</Button>
                    }
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Container>
    )
  }
}
