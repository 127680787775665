import React from "react";
var FontAwesome = require("react-fontawesome");
import ReactHtmlParser from 'react-html-parser';
// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Table,
  Row,
  Col,
  Form,
  Modal
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { HTTP } from '../packages/axios';
import moment from 'moment-timezone';
import { notify } from 'react-notify-toast';
import ImageZoom from "react-medium-image-zoom";
import OverflowScrolling from 'react-overflow-scrolling';
import Pagination from 'react-bootstrap-4-pagination';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Switch from "react-switch";

function StylistProfile() {

  const [data, setData] = React.useState({ availabilities: [] });
  const [appointments, setAppointments] = React.useState({ orders: [] });
  const [services, setServices] = React.useState({ provider_services: { service_categories: [] }, count: 0 });
  const [isLoading, setIsLoading] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);
  const [showModalApprove, setShowModalApprove] = React.useState(false);
  const [showModalReject, setShowModalReject] = React.useState(false);
  const [backgroundCheck, setBackgroundCheck] = React.useState(false);
  const [paginationConfig, setpaginationConfig] = React.useState({});
  const [perPageLimit, setPerPageLimit] = React.useState(10);
  const [apptCount, setApptCount] = React.useState(0);
  const [status, setStatus] = React.useState(0);
  const [remarks, setRemarks] = React.useState('Your profile has been rejected for the reason ');
  const { id } = useParams();

  const getStylist = () => {
    let query = { where: { id }, populate: '["stripe"]' };
    HTTP.get('/user/find', { params: query }, {
    }).then(response => {
      if (response.data) {
        // console.log(response.data.data);
        setData(response.data.data);
        setIsLoading(false);

      }
    }).catch(error => {
      console.log(error);
    });
  }

  const getAppointments = (offset) => {
    let query = {
      where: { provider_id: id },
      // upcoming_only: true 
    };
    query.limit = perPageLimit;
    query.offset = offset;
    query.sort = '[["created_at", "DESC"]]';
    HTTP.get('/order/all', { params: query }, {
    }).then(response => {
      if (response.data) {
        setAppointments(response.data.data);
        setApptCount(response.data.data.count)
        setIsLoading(false);
        if (response.data.data.orders.length > 0) {
          setpaginationConfig({
            totalPages: Math.ceil(response.data.data.count / response.data.data.limit),
            currentPage: Math.ceil((response.data.data.limit + response.data.data.offset) / response.data.data.limit),
            showMax: 5,
            size: "sm",
            threeDots: false,
            onClick: function (e, page) {
              e.preventDefault();
              getAppointments((page - 1) * response.data.data.limit);
            }
          })
        }
      }
    }).catch(error => {
      console.log(error);
    });
  }

  const getServices = () => {
    HTTP.get('/service/provider/' + id, {}, {
    }).then(response => {
      if (response.data) {
        setServices(response.data.data);
        setIsLoading(false);
      }
    }).catch(error => {
      console.log(error);
    });
  }

  const toggleBackGround = () => {
    setBackgroundCheck(true)
    const background_checked = !!data.user.background_checked ? false : true;
    HTTP.put('/user/background/' + id, {
      background_checked
    }, {
    }).then(response => {
      setBackgroundCheck(false)
      setIsLoading(false);
      // notify.show(background_checked ? 'background_checked false' : 'background_checked true', 'success');
      getStylist();
    }).catch(error => {
      setBackgroundCheck(false)
      console.log(error);
    });
  }

  const blockUser = (e) => {
    e.preventDefault();
    notify.hide();
    setIsLoading(true);
    setShowModal(false);
    const active = data.user.active ? false : true;
    HTTP.put('/user/' + id, {
      active
    }).then(response => {
      setIsLoading(false);
      notify.show(active ? 'Successfully Unblocked' : 'Successfully Blocked', 'success');
      getStylist();
    }).catch(error => {
      notify.show("Something went wrong!", 'error');
      getStylist();
      console.log(error);
    });
  }

  const toggleAprroveAndReject = (e, status) => {
    e.preventDefault();
    setStatus(status);
    if (status == 1) setShowModalApprove(true);
    if (status == -1) setShowModalReject(true);
  }

  const ApproveOrRejectUser = (e) => {
    e.preventDefault();
    notify.hide();
    setIsLoading(true);
    setShowModalApprove(false);
    setShowModalReject(false);
    HTTP.put('/user/' + id, {
      status,
      remarks: status == -1 ? remarks : null
    }).then(response => {
      setIsLoading(false);
      notify.show(status == -1 ? 'Successfully Rejected' : 'Successfully Approved', 'success');
      getStylist();
    }).catch(error => {
      notify.show("Something went wrong!", 'error');
      console.log(error);
    });
  }

  React.useEffect(() => {
    getStylist();
    getServices();
    getAppointments();
  }, [])

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="6">
            <Card className="mb-0 mb-3 d-flex">
              <Card.Header className="d-flex align-items-center justify-content-between border-bottom-1">
                <div className="d-flex align-items-center w-100 pb-3">
                  <div>
                    <img
                      alt="..."
                      className="avatar-100 mr-4"
                      src={
                        data.user && data.user.image
                          ? data.user.image
                          : require("assets/img/faces/face-0.jpg").default
                      }
                    ></img>
                  </div>
                  <div>
                    <div className="d-flex align-items-center">
                      {data.user && data.user.active == 0 ? (
                        <span className="badge badge-rejected">
                          <FontAwesome className="mr-1" name="times" />
                          Blocked
                        </span>
                      ) : (
                        <>
                          {data.user && data.user.status == 0 && (
                            <span className="badge badge-pending">
                              <FontAwesome
                                className="mr-1"
                                name="pause-circle"
                              />
                              Pending
                            </span>
                          )}
                          {data.user && data.user.status == 1 && (
                            <span className="badge badge-active">
                              <FontAwesome className="mr-1" name="check" />
                              Approved
                            </span>
                          )}
                          {data.user && data.user.status == -1 && (
                            <span className="badge badge-rejected">
                              <FontAwesome className="mr-1" name="times" />
                              Rejected
                            </span>
                          )}
                          {data.user && data.user.status == -2 && (
                            <span className="badge badge-rejected">
                              <FontAwesome className="mr-1" name="times" />
                              Incomplete
                            </span>
                          )}
                          {data.user && data.user.status == -5 && (
                            <span className="badge badge-rejected">
                              <FontAwesome className="mr-1" name="times" />
                              Deleted
                            </span>
                          )}
                        </>
                      )}
                      <span className="font-weight-bold text-secondary p-2">
                        {data.user ? "#ISRA" + data.user.id : ""}
                      </span>
                    </div>
                    <h3 className="my-2">
                      {data.user ? data.user.full_name : ""}
                    </h3>
                    <div className="d-flex justify-content-center">
                      <div className="border-right pr-2">
                        {data.user && data.user.phone
                          ? "(" + data.user.phone_code + ") " + data.user.phone
                          : ""}
                        {data.user && data.user.is_phone_verified == 1 && (
                          <div className="badge badge-active-phone">
                            <FontAwesome className="mr-1" name="check" />
                          </div>
                        )}
                      </div>
                      <div className="border-right px-2">
                        {data.user ? data.user.email : ""}
                      </div>
                      <div className="pl-2">
                        {data.profile && data.profile.instagram ? (
                          <a
                            href={
                              "https://www.instagram.com/" +
                              data.profile.instagram
                                .replace("instagram.com/", "")
                                .replace("@", "")
                                .replace("https://", "")
                            }
                            target="_blank"
                          >
                            <img
                              alt="..."
                              className="mr-2"
                              src={require("assets/img/Insta.png").default}
                            ></img>
                          </a>
                        ) : (
                          ""
                        )}
                        {data.profile && data.profile.facebook ? (
                          <a
                            href={
                              "https://www.facebook.com/" +
                              data.profile.facebook
                                .replace("https://", "")
                                .replace("http://", "")
                            }
                            target="_blank"
                          >
                            <img
                              alt="..."
                              className="mr-2"
                              src={require("assets/img/facebook.png").default}
                            ></img>
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="ml-auto">
                    <Link to="/admin/stylists">
                      <Button className="btn btn-outnile">
                        <FontAwesome
                          className="mr-1"
                          name="arrow-left"
                          size="sm"
                        />
                        Back
                      </Button>
                    </Link>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className="px-0">
                <div className="p-4 border-top">
                  <div className="font-weight-bold text-muted mb-3">
                    Verify documents
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <h5>Identity Card (Front & Back)</h5>
                      <div className="row">
                        <div className="col-6 text-center">
                          <div>
                            <ImageZoom
                              image={{
                                src: data.profile
                                  ? data.profile.id_front_image
                                  : "",
                                className: "img",
                                style: { width: "10em" },
                              }}
                              zoomImage={{
                                src: data.profile
                                  ? data.profile.id_front_image
                                  : "",
                              }}
                            />
                          </div>
                          <p>FRONT</p>
                        </div>
                        <div className="col-6 text-center">
                          <div>
                            <ImageZoom
                              image={{
                                src: data.profile
                                  ? data.profile.id_back_image
                                  : "",
                                className: "img",
                                style: { width: "10em" },
                              }}
                              zoomImage={{
                                src: data.profile
                                  ? data.profile.id_back_image
                                  : "",
                              }}
                            />
                          </div>
                          <p>Back</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 text-center">
                      <h5>Cosmetology License</h5>
                      <div>
                        <ImageZoom
                          image={{
                            src: data.profile
                              ? data.profile.cosmetology_licence_image
                              : "",
                            className: "img",
                            style: { width: "10em" },
                          }}
                          zoomImage={{
                            src: data.profile
                              ? data.profile.cosmetology_licence_image
                              : "",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-top p-4">
                  <div className="row">
                    <div
                      className="col"
                      hidden={data.user && (data.user.status == 0 || data.user?.status == -5)}
                    >
                      {data.user && data.user.active ? (
                        <Button
                          className="btn-outline btn-danger btn-block d-flex align-items-center justify-content-center"
                          type="submit"
                          variant="info"
                          onClick={(e) => setShowModal(true)}
                        >
                          <FontAwesome className="mr-1" name="ban" size="sm" />
                          Block User
                        </Button>
                      ) : (
                        <Button
                          className="btn-outline btn-sucess btn-block d-flex align-items-center justify-content-center"
                          type="submit"
                          variant="info"
                          onClick={(e) => setShowModal(true)}
                        >
                          <FontAwesome className="mr-1" name="ban" size="sm" />
                          Unblock
                        </Button>
                      )}
                      <Modal
                        className="modal-mini modal-primary"
                        show={showModal}
                        onHide={() => setShowModal(false)}
                      >
                        <Modal.Header className="justify-content-center">
                          <div className="modal-profile">
                            <i className="nc-icon nc-bulb-63"></i>
                          </div>
                        </Modal.Header>
                        <Modal.Body className="text-center">
                          <p>Are you sure, Do you want to continue?</p>
                        </Modal.Body>
                        <div className="modal-footer">
                          <Button
                            className="btn-simple"
                            type="button"
                            variant="link"
                            onClick={(e) => setShowModal(false)}
                          >
                            Close
                          </Button>
                          <Button
                            className="btn-simple"
                            type="button"
                            variant="danger"
                            onClick={(e) => blockUser(e)}
                          >
                            Continue
                          </Button>
                        </div>
                      </Modal>
                    </div>
                    {data.user && data.user.active && data.user?.status != -5 ? (
                      <React.Fragment>
                        <div
                          className="col"
                          hidden={
                            data.user &&
                            (data.user.status == -1 || data.user.status == -2)
                          }
                        >
                          <Button
                            className="btn-fill btn-danger btn-block d-flex align-items-center justify-content-center"
                            type="submit"
                            variant="info"
                            onClick={(e) => {
                              toggleAprroveAndReject(e, -1);
                            }}
                          >
                            <FontAwesome
                              className="mr-1"
                              name="times-circle"
                              size="sm"
                            />
                            Reject
                          </Button>
                        </div>
                        <div
                          className="col"
                          hidden={
                            data.user &&
                            (data.user.status > 0 || data.user.status == -2 || data.user.status == -1)
                          }
                        >
                          <Button
                            className="btn-fill btn-sucess btn-block d-flex align-items-center justify-content-center"
                            type="submit"
                            variant="info"
                            onClick={(e) => {
                              toggleAprroveAndReject(e, 1);
                            }}
                          >
                            <FontAwesome
                              className="mr-1"
                              name="check-circle"
                              size="sm"
                            />
                            Approve
                          </Button>
                        </div>
                      </React.Fragment>
                    ) : (
                      ""
                    )}

                    <Modal
                      className="modal-mini modal-primary"
                      show={showModalApprove}
                      onHide={() => setShowModalApprove(false)}
                    >
                      <Modal.Header className="justify-content-center">
                        <div className="modal-profile">
                          <i className="nc-icon nc-bulb-63"></i>
                        </div>
                      </Modal.Header>
                      <Modal.Body className="text-center">
                        <p>Are you sure, Do you want to continue?</p>
                      </Modal.Body>
                      <div className="modal-footer">
                        <Button
                          className="btn-simple"
                          type="button"
                          variant="link"
                          onClick={(e) => setShowModalApprove(false)}
                        >
                          Close
                        </Button>
                        <Button
                          className="btn-simple"
                          type="button"
                          variant="danger"
                          onClick={(e) => ApproveOrRejectUser(e)}
                        >
                          Continue
                        </Button>
                      </div>
                    </Modal>

                    <Modal
                      className="modal-primary"
                      show={showModalReject}
                      onHide={() => setShowModalReject(false)}
                    >
                      <Modal.Header className="justify-content-center">
                        <div className="modal-profile">
                          <i className="nc-icon nc-bulb-63"></i>
                        </div>
                      </Modal.Header>
                      <Form onSubmit={(e) => ApproveOrRejectUser(e)}>
                        <Modal.Body className="text-center">
                          <p>Please enter reason for rejection</p>
                          <Row className="justify-content-md-center">
                            <Col xs="10">
                              <Form.Group>
                                <Form.Control
                                  as="textarea"
                                  placeholder="Rejection reason"
                                  onChange={(e) => setRemarks(e.target.value)}
                                  value={remarks}
                                  required
                                ></Form.Control>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                          <Button
                            className="btn-simple"
                            type="button"
                            variant="link"
                            onClick={(e) => setShowModalReject(false)}
                          >
                            Close
                          </Button>
                          <Button
                            className="btn-simple"
                            type="submit"
                            variant="danger"
                          >
                            Continue
                          </Button>
                        </div>
                      </Form>
                    </Modal>
                  </div>
                </div>
                <div className="px-5 border-top">

                  <div className="row border-dotted-bottom py-3" hidden={
                    (data.user && data.user.status != 1)
                  } style={{ background: "#e5e5e5" }}>
                    <div className="col-md-8">
                      <div className="">
                        <div className="font-weight-bold text-black align-self-center">
                          Background Check ?
                        </div>
                        <div className="">
                          {!!data.user && (
                            <>
                              {!!data.user.background_checked && (
                                <span className="badge badge-active">
                                  <FontAwesome className="mr-1" name="check" />
                                  Verified
                                </span>
                              )}
                              {!data.user.background_checked && (
                                <span className="badge badge-pending">
                                  <FontAwesome
                                    className="mr-1"
                                    name="pause-circle"
                                  />
                                  Unverified
                                </span>
                              )}
                            </>
                          )}
                        </div>
                      </div>

                    </div>
                    <div className="col-md-4 d-flex justify-content-center align-items-center">
                      {/* <div
                          className="col"
                          hidden={
                            (data.user && data.user.background_checked == 1)
                          }
                        >
                          <Button
                            className="btn-fill btn-sucess btn-block d-flex align-items-center justify-content-center"
                            type="submit"
                            variant="info"
                            onClick={toggleBackGround}
                          // disabled={data.user.background_checked==1}
                          >
                            <FontAwesome
                              className="mr-1"
                              name="check-circle"
                              size="sm"
                            />
                            YES
                          </Button>
                        </div>
                        <div
                          className="col"
                          hidden={
                            (data.user && data.user.background_checked == 0)
                          }
                        >
                          <Button
                            className="btn-fill btn-danger btn-block d-flex align-items-center justify-content-center"
                            type="submit"
                            variant="info"
                            onClick={toggleBackGround}
                          >
                            <FontAwesome
                              className="mr-1"
                              name="times-circle"
                              size="sm"
                            />
                            NO
                          </Button>
                        </div> */}

                      <Switch
                        onChange={toggleBackGround}
                        checked={!!data.user && !!data.user.background_checked}
                        disabled={backgroundCheck}
                        offColor={"#FF0000"}
                      />
                      {/* <div>
                        {data.profile
                          ? data.profile.provider_type.charAt(0) +
                          data.profile.provider_type
                            .slice(1)
                            .toLowerCase()
                            .replace("_", "")
                          : ""}
                      </div> */}
                    </div>
                  </div>



                  <div className="row border-dotted-bottom py-3">
                    <div className="col-md-6">
                      <div className="font-weight-bold text-muted">
                        Bank Account
                      </div>
                      <div>
                        {!!data.user && (
                          <>
                            {(data.user.stripe == null && data.user.status != -5) && (
                              <span className="badge badge-rejected">
                                <FontAwesome className="mr-1" name="times" />
                                Not available
                              </span>
                            )}

                            {(data.user.stripe != null && data.user.status != -5) &&
                              !!data.user.stripe.active && (
                                <span className="badge badge-active">
                                  <FontAwesome className="mr-1" name="check" />
                                  Active
                                </span>
                              )}
                            {(data.user.stripe != null && data.user.status != -5) &&
                              !data.user.stripe.active && (
                                <span className="badge badge-pending">
                                  <FontAwesome
                                    className="mr-1"
                                    name="pause-circle"
                                  />
                                  Inactive
                                </span>
                              )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="font-weight-bold text-muted">
                        Type of Service
                      </div>
                      <div>
                        {data.profile
                          ? data.profile.provider_type.charAt(0) +
                          data.profile.provider_type
                            .slice(1)
                            .toLowerCase()
                            .replace("_", "")
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="row border-dotted-bottom py-3">
                    <div className="col-md-6">
                      <div className="font-weight-bold text-muted">Address</div>
                      <div>{data.user ? data.user.address : ""}</div>
                    </div>
                    <div className="col-md-6">
                      <div className="font-weight-bold text-muted">State</div>
                      <div>{data.user && data.user.state ? data.user.state : "N/A"}</div>
                    </div>

                  </div>
                  <div className="row border-dotted-bottom py-3">
                    <div className="col-md-6">
                      <div className="font-weight-bold text-muted">
                        Reviews{" "}
                      </div>
                      <Link
                        to={
                          data.reviews && data.reviews.length
                            ? "/admin/stylist/profile/" + id + "/reviews"
                            : "#"
                        }
                      >
                        {data.reviews ? data.reviews.length : 0} Reviews
                      </Link>
                    </div>
                    <div className="col-md-6">
                      <div className="font-weight-bold text-muted">
                        Gallery{" "}
                      </div>
                      <Link
                        to={
                          data.gallery && data.gallery.length
                            ? "/admin/stylist/profile/" + id + "/gallery"
                            : "#"
                        }
                      >
                        {data.gallery ? data.gallery.length : 0} Photos
                      </Link>
                    </div>
                  </div>
                  <div className="row border-dotted-bottom py-3">
                    <div className="col-md-6">
                      <div className="font-weight-bold text-muted">
                        Cosmetology license
                      </div>
                      <div>
                        {data.profile ? data.profile.cosmetology_licence : ""}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="font-weight-bold text-muted">
                        Year of Experience
                      </div>
                      <div>{data.profile ? data.profile.experience : ""}</div>
                    </div>
                  </div>
                  <div className="row border-dotted-bottom py-3">
                    <div className="col-md-6">
                      <div className="font-weight-bold text-muted">
                        Hair salon worked
                      </div>
                      <div>{data.profile ? data.profile.prev_saloon : ""}</div>
                    </div>
                    <div className="col-md-6">
                      <div className="font-weight-bold text-muted">
                        Date of Birth
                      </div>
                      <div>
                        {data.profile
                          ? moment().diff(data.profile.dob, "years", false) +
                          " Years. " +
                          moment(data.profile.dob).format("MMMM D, YYYY")
                          : ""}
                      </div>
                    </div>
                  </div>

                  <div className="row border-dotted-bottom py-3">
                    <div className="col-md-6">
                      <div className="font-weight-bold text-muted">
                        Service offered
                      </div>
                      <div>
                        {data.profile
                          ? "@" +
                          data.profile.service_location.charAt(0) +
                          data.profile.service_location.slice(1).toLowerCase()
                          : ""}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="font-weight-bold text-muted">
                        Wkly Services
                      </div>
                      <div>
                        {data.user
                          ? data.user.frequency ? data.user.frequency : "N/A"
                          : "N/A"}
                      </div>
                    </div>
                  </div>

                  <div className="row py-3">
                    <div className="col-12">
                      <div className="font-weight-bold text-muted">
                        About Stylist
                      </div>
                      <div>
                        {data.profile
                          ? ReactHtmlParser(data.profile.about)
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-top border-bottom p-4">
                  <div>
                    <div className="font-weight-bold text-muted">
                      Availabilities
                    </div>
                    <p hidden={data.availabilities.length > 0}>
                      No availabilities found
                    </p>
                    {data.availabilities.map((availability, key) => {
                      return (
                        <React.Fragment>
                          <div className="row font-weight-bold my-2">
                            <div className="col-md-4 col-xs-5">
                              {" "}
                              {moment()
                                .day(availability.day_of_week)
                                .format("dddd")}{" "}
                            </div>
                            <div className="col-md-4 col-xs-5">
                              {" "}
                              {moment(availability.start_time).format(
                                "hh:mm A"
                              )}{" "}
                              -{" "}
                              {moment(availability.end_time).format("hh:mm A")}{" "}
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
                <div className="p-4">
                  <div className="row  align-items-center">
                    <div className="col-xs-12 col-md-4 col-xl-3">
                      <div className="font-weight-bold text-muted">
                        Active from
                      </div>
                      <div>
                        {data.user
                          ? moment(data.user.created_at).format("MM/DD/YYYY")
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-top border-bottom p-4">
                  <div className="row  align-items-center">
                    <div className="col-xs-12 col-md-4 col-xl-5">
                      <div className="font-weight-bold text-muted">
                        Stripe Id
                      </div>
                      <div>
                        {data.user &&
                          data.user.stripe &&
                          data.user?.stripe.stripe_id
                          ? data.user.stripe.stripe_id
                          : "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
                {data.user?.status != -5 && <div className="border-top border-bottom p-4">
                  <div className="row  align-items-center">
                    <div className="col-xs-12 col-md-4 col-xl-5">
                      <div className="font-weight-bold text-muted">
                        Share URL
                      </div>
                      <div>
                        {(!data.profile || !data.profile.share_url) && "N/A"}
                      </div>
                      {(!!data.profile && !!data.profile.share_url) && (
                        <>
                          <CopyToClipboard
                            text={data.profile.share_url}
                            onCopy={() => { notify.show('Copied to clipboard', 'success'); }}
                          >
                            <Button>{data.profile.share_url}</Button>
                          </CopyToClipboard>
                        </>
                      )}
                    </div>
                  </div>
                </div>}
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" className="flex">
            <Card className="mb-0">
              <Card.Body className="d-flex align-items-center justify-content-between">
                <h4 className="m-0">Services ({services.count})</h4>
              </Card.Body>
            </Card>
            <OverflowScrolling className="overflow-scrolling">
              <Card className="mb-0 services-card stylist-services">
                {services.provider_services.service_categories.map(
                  (serviceCategory, key) => {
                    return (
                      <React.Fragment>
                        <Card.Header className="d-flex align-items-center justify-content-between">
                          <Card.Title as="h4" className="main-catagory">
                            {serviceCategory.category}
                          </Card.Title>
                        </Card.Header>
                        {serviceCategory.service_types.map(
                          (serviceType, key) => {
                            return (
                              <Card.Body className="p-3">
                                <h4>{serviceType.type}</h4>
                                <ul>
                                  <li hidden={serviceType.services.length > 0}>
                                    No services available
                                  </li>
                                  {serviceType.services.map((service, key) => {
                                    return (
                                      <li>
                                        <span className="mr-auto">
                                          {service.service_name}
                                        </span>{" "}
                                        <span>
                                          <span className="mr-3">
                                            {service.provider_service
                                              ? service.provider_service
                                                .required_time + " min"
                                              : "N/A"}
                                          </span>
                                          <span className="font-weight-bold">
                                            {service.provider_service &&
                                              service.provider_service.cost
                                              ? "$" +
                                              parseFloat(
                                                service.provider_service.cost
                                              ).toLocaleString()
                                              : "N/A"}
                                          </span>
                                        </span>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </Card.Body>
                            );
                          }
                        )}
                      </React.Fragment>
                    );
                  }
                )}
              </Card>
            </OverflowScrolling>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className="mb-0 table-card">
              <Card.Header className="d-flex align-items-center justify-content-between">
                <div>
                  <Card.Title as="h4">Appointments ({apptCount})</Card.Title>
                </div>
                <Form.Group
                  controlId="exampleForm.ControlSelect1"
                  className="inline-select mr-4"
                ></Form.Group>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive p-0">
                <Table className="table-hover table-bordered table-type01 mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Booking ID</th>
                      <th>Amount</th>
                      <th>Customer Details</th>
                      <th>Service Type</th>
                      <th>Booked on</th>
                      <th>Service Status</th>
                    </tr>
                  </thead>
                  <p hidden={appointments.orders.length > 0}>
                    No records found
                  </p>
                  <tbody hidden={appointments.orders.length == 0}>
                    {appointments.orders.map((order, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <Link to={"/admin/appointment/" + order.id}>
                            #ISRA{order.id}
                          </Link>
                        </td>
                        <td className="text-bold">
                          {"$" +
                            parseFloat(order.order_amount).toLocaleString()}
                        </td>
                        <td className="font-weight-bold">
                          <a className="td-user-thumb">
                            <img
                              alt="..."
                              className="user-40"
                              src={
                                order.customer.image
                                  ? order.customer.image
                                  : require("assets/img/faces/face-0.jpg")
                                    .default
                              }
                            ></img>
                            <p> {order.customer.full_name} </p>
                          </a>
                        </td>

                        <td>
                          <div className="service-icon">
                            {order.service_location == "MOBILE" ? (
                              <React.Fragment>
                                <span className="at-home"></span>Home
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <span className="at-salon"></span>Salon
                              </React.Fragment>
                            )}
                          </div>
                        </td>
                        <td>
                          {" "}
                          {moment(order.created_at).format("MM/DD/YYYY h:mm A")}
                        </td>
                        <td>
                          {order.order_status == "CANCELLED" ? (
                            <span
                              className="badge badge-pending"
                              title={order.remarks}
                            >
                              <FontAwesome className="mr-1" name="times" />
                              {order.order_status.charAt(0) +
                                order.order_status
                                  .slice(1)
                                  .toLowerCase()
                                  .replace("_", " ")}
                            </span>
                          ) : (
                            <span className="badge badge-warning badge-active">
                              <FontAwesome
                                className="mr-1"
                                name="check"
                                size="sm"
                              />
                              {order.order_status.charAt(0) +
                                order.order_status
                                  .slice(1)
                                  .toLowerCase()
                                  .replace("_", " ")}
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
              <div hidden={appointments.orders.length == 0}>
                <Card.Footer className="text-center d-flex justify-content-end">
                  <Pagination {...paginationConfig} />
                </Card.Footer>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default StylistProfile;
