import React from "react";
var FontAwesome = require("react-fontawesome");
// react-bootstrap components
import {
  Card,
  Container,
  Table,
  Row,
  Col,
  Form,
  Spinner
} from "react-bootstrap";
import { HTTP } from '../packages/axios';
import moment from 'moment-timezone';
import Pagination from 'react-bootstrap-4-pagination';
import { Link } from "react-router-dom";

function Appointments() {

  const [appointments, setAppointments] = React.useState({ orders: [] });
  const [isLoading, setIsLoading] = React.useState(true);
  const [paginationConfig, setpaginationConfig] = React.useState({});
  const [whereCondition, setwhereCondition] = React.useState({});
  const [perPageLimit, setPerPageLimit] = React.useState(10);
  const [sort, setSort] = React.useState({
    name: "created_at",
    order: "desc",
    icon: "fa-sort",
  });

  const getAppointments = (query) => {
    query.where = { ...query.where, ...whereCondition };
    query.limit = perPageLimit;
    query.sort = sort.name && sort.order ? `[["${sort.name}","${sort.order}"]]` : "",
      HTTP.get('/order/all', { params: query }, {
      }).then(response => {
        if (response.data) {
          setAppointments(response.data.data);
          setIsLoading(false);
          if (response.data.data.orders.length > 0) {
            setpaginationConfig({
              totalPages: Math.ceil(response.data.data.count / response.data.data.limit),
              currentPage: Math.ceil((response.data.data.limit + response.data.data.offset) / response.data.data.limit),
              showMax: 5,
              size: "sm",
              threeDots: false,
              onClick: function (e, page) {
                e.preventDefault();
                getAppointments({ offset: (page - 1) * response.data.data.limit });
              }
            })
          }
        }
      }).catch(error => {
        console.log(error);
      });
  }

  const changeDataLimit = (e) => {
    setPerPageLimit(e.target.value);
  }

  const filterData = (e) => {
    setwhereCondition({ order_status: e.target.value == 'all' ? undefined : e.target.value });
  }

  const sortAppointments = (name) => {
    setSort({
      ...sort,
      name,
      order: !sort.order ? "asc" : sort.order == "asc" ? "desc" : "",
      icon: !sort.order
        ? "fa-sort-down"
        : sort.order == "asc"
          ? "fa-sort-up"
          : "fa-sort",
    });
  };

  React.useEffect(() => {
    getAppointments({});
  }, [whereCondition, perPageLimit, sort])

  return (
    <>
      <Container fluid>
        <Spinner animation="border" role="status" hidden={!isLoading}>
          <span className="sr-only">Loading...</span>
        </Spinner>
        <div hidden={!!isLoading}>
          <Row>
            <Col md="12">
              <Card className="mb-0 table-card">
                <Card.Header className="d-flex align-items-center justify-content-between">
                  <div>
                    <Card.Title as="h4">Appointments</Card.Title>
                    <p className="card-category">You have {appointments.count} Appointments</p>
                  </div>
                  <Form.Group
                    controlId="exampleForm.ControlSelect1"
                    className="inline-select mr-4"
                  >
                    <Form.Control as="select" onChange={(e) => filterData(e)} value={whereCondition.order_status}>
                      <option selected disabled>Filter By</option>
                      <option value="all">All</option>
                      <option value="confirmed">Confirmed</option>
                      <option value="cancelled">Cancelled</option>
                      <option value="completed">Completed</option>
                      <option value="drive_started">Drive started</option>
                      <option value="location_reached">Location reached</option>

                    </Form.Control>
                  </Form.Group>
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0">
                  <Table className="table-hover table-bordered table-type01">
                    <thead>
                      <tr>
                        <th className="border-0">#</th>
                        <th className="border-0">Booking ID{" "}<i
                          className={`fas ${sort.name == "id" ? sort.icon : "fa-sort"
                            } text-black`}
                          onClick={() => sortAppointments("id")}
                          style={{ cursor: "pointer" }}
                        /></th>
                        <th className="border-0">Transaction ID</th>
                        <th className="border-0">Amount{" "}<i
                          className={`fas ${sort.name == "order_amount" ? sort.icon : "fa-sort"
                            } text-black`}
                          onClick={() => sortAppointments("order_amount")}
                          style={{ cursor: "pointer" }}
                        /></th>
                        <th className="border-0">Customer Details</th>
                        <th className="border-0">Stylist Details</th>
                        <th className="border-0">Service Type{" "}<i
                          className={`fas ${sort.name == "service_location" ? sort.icon : "fa-sort"
                            } text-black`}
                          onClick={() => sortAppointments("service_location")}
                          style={{ cursor: "pointer" }}
                        /></th>
                        <th className="border-0">Booked on{" "}<i
                          className={`fas ${sort.name == "booked_on" ? sort.icon : "fa-sort"
                            } text-black`}
                          onClick={() => sortAppointments("booked_on")}
                          style={{ cursor: "pointer" }}
                        /></th>
                        <th className="border-0">Service Status{" "}<i
                          className={`fas ${sort.name == "order_status" ? sort.icon : "fa-sort"
                            } text-black`}
                          onClick={() => sortAppointments("order_status")}
                          style={{ cursor: "pointer" }}
                        /></th>
                      </tr>
                    </thead>
                    <p hidden={appointments.orders.length > 0}>No records found</p>
                    <tbody hidden={appointments.orders.length == 0}>
                      {appointments.orders.map((order, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <Link to={"/admin/appointment/" + order.id}>#ISRA{order.id}</Link>
                          </td>
                          <td>
                            {order.transactions && order.transactions.length > 0 ? order.transactions.filter((e) => e.type == "ORDER").length > 0 ?
                              order.transactions.filter((e) => e.type == "ORDER")[0].transaction_id : "N/A" : "N/A"}
                          </td>
                          <td className="text-bold">{'$' + parseFloat(order.order_amount).toLocaleString()}</td>
                          <td className="font-weight-bold">
                            <a className="td-user-thumb">
                              <img
                                alt="..."
                                className="user-40"
                                src={order.customer.image ? order.customer.image : require("assets/img/faces/face-0.jpg").default}
                              ></img>
                              <p> <Link to={"/admin/customer/profile/" + order.customer.id}>{order.customer.full_name}</Link> </p>
                            </a>
                          </td>

                          <td className="font-weight-bold">
                            <a className="td-user-thumb">
                              <img
                                alt="..."
                                className="user-40"
                                src={order.provider.image ? order.provider.image : require("assets/img/faces/face-0.jpg").default}
                              ></img>
                              <p> <Link to={"/admin/stylist/profile/" + order.provider.id}>{order.provider.full_name}</Link> </p>
                            </a>
                          </td>

                          <td>
                            <div className="service-icon">
                              {order.service_location == "MOBILE" ?
                                <React.Fragment>
                                  <span className="at-home"></span>Home
                                </React.Fragment>
                                :
                                <React.Fragment>
                                  <span className="at-salon"></span>Salon
                                </React.Fragment>
                              }
                            </div>
                          </td>
                          <td>
                            {" "}
                            {moment(order.created_at).format('MM/DD/YYYY h:mm A')}
                          </td>
                          <td>
                            {order.order_status == 'CANCELLED' ?
                              <span className="badge badge-pending" title={order.remarks}>
                                <FontAwesome
                                  className="mr-1"
                                  name="times"
                                />
                                {order.order_status.charAt(0) + order.order_status.slice(1).toLowerCase().replace('_', ' ')}
                              </span>
                              :
                              <span className="badge badge-warning badge-active">
                                <FontAwesome
                                  className="mr-1"
                                  name="check"
                                  size="sm"
                                />
                                {order.order_status.charAt(0) + order.order_status.slice(1).toLowerCase().replace('_', ' ')}
                              </span>
                            }
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
                <div hidden={appointments.orders.length == 0}>
                  <Card.Footer className="text-center d-flex justify-content-end">
                    <Form.Group
                      controlId="exampleForm.ControlSelect1"
                      className="inline-select mr-4"
                    >
                      <Form.Label
                        style={{ minWidth: 180, textTransform: "capitalize" }}
                      >
                        Items per page
                      </Form.Label>
                      <Form.Control as="select" onChange={(e) => changeDataLimit(e)}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                      </Form.Control>
                    </Form.Group>
                    <Pagination {...paginationConfig} />
                  </Card.Footer>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}

export default Appointments;
