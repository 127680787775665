import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

export function exportAsExcelFile(json, excelFileName, myHeader) {
    const header = Object.keys(json[0]); // columns name
    var wscols = [];
    for (var i = 0; i < header.length; i++) {  // columns length added
        wscols.push({ wch: header[i].length + 8 })
    }

    const worksheet = XLSX.utils.json_to_sheet(json, { header: myHeader });
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    range.e['c'] = myHeader.length - 1;
    worksheet['!ref'] = XLSX.utils.encode_range(range);
    worksheet["!cols"] = wscols;
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'], };

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    saveAsExcelFile(excelBuffer, excelFileName);
}

function saveAsExcelFile(buffer, fileName) {
    const data = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + new Date().toLocaleString() + EXCEL_EXTENSION);
}

