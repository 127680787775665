import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function WIP() {
  return (
    <>
      <Container fluid>
        <Row>
          <p>Work in progress...</p>
        </Row>
      </Container>
    </>
  );
}

export default WIP;
