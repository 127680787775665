import React from "react";
// react-bootstrap components
import {
  Card,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import {HTTP} from '../packages/axios';
import moment from 'moment-timezone';
import { Link  } from "react-router-dom";

function Notifications() {
  const [data, setData] = React.useState({notifications: []});
  const date = [];

  const getNotifications = (query) => {
    HTTP.get('/user/notifications', {params: query}, {
    }).then(response => {
        if(response.data){
          setData(response.data.data);
        }
    }).catch(error => {
        console.log(error);
    });
  }

  const renderNotificationPayload = (payload) => {
    let link = '/admin/notifications';
    switch(payload.type){
      case 'stylist_awaiting':
          link = `/admin/stylist/profile/${payload.user_id}`;
    }
    return(
      <Row className="mb-3">
          <Col md="12" lg="10" xl="9">
            <Link to={link}>
              <Card className="mb-1">
                <Card.Body className="notofication-list">
                  <h5 className="text-primary font-weight-bold mb-1">
                    {payload.title}
                  </h5>
                    {payload.body}
                </Card.Body>
              </Card>
            </Link>
          </Col>
      </Row>
    );
  }

  const renderDate = (created_at) => {
    
    if(!date.includes(created_at)){
      date.push(created_at);
    }else{
      created_at = null;
    }

    return(
      created_at ? 
      <Row className="mb-2">
        <Col md="12" lg="10" className="font-weight-bold mb-2">
          {moment(created_at).format('MMMM D, YYYY, dddd')}
        </Col>
      </Row>
      : ''
    )
  }

  React.useEffect(() => {
    getNotifications({});
  }, [])

  return (
    <>
      <div className="rna-container"></div>
      <Container fluid>
      {data.notifications.map((notification, index) => (
        <React.Fragment>
            { renderDate(notification.created_at) }
            { renderNotificationPayload(notification.payload) }
        </React.Fragment>
      )) }
      </Container>
    </>
  );
}

export default Notifications;
