import React from "react";
var FontAwesome = require("react-fontawesome");
// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Form,
  Tabs,
  Tab,
  ButtonGroup,
  FormControl,
  InputGroup,
  Modal,
} from "react-bootstrap";
import { HTTP } from '../packages/axios';
import { notify } from 'react-notify-toast';
import moment from 'moment-timezone';

function Settings() {
  const [showModal, setShowModal] = React.useState(false);
  const [showWarningModal, setShowWarningModal] = React.useState(false);
  const [password, setPassword] = React.useState(null);
  const [confirmPassword, setConfirmPassword] = React.useState(null);
  const [newPassword, setnewPassword] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState({ settings: [] });
  const [stripeMode, setstripeMode] = React.useState('TEST');
  const [paymentSetting, setpaymentSetting] = React.useState({ 'base_fare': {}, 'transportation_charge': {}, 'admin_commission': {}, 'cancellation_fee': {}, 'admin_email': {} });
  const [reload, setReload] = React.useState(false);
  const [states, setStates] = React.useState({ states: [] });
  const [taxStates, setTaxStates] = React.useState({ states: [] });
  const [state, setState] = React.useState(null);
  const [taxPercent, setTaxPercent] = React.useState(null);
  const [deleteStateId, setDeleteStateId] = React.useState(null);
  const [editMode, setEditMode] = React.useState(false);

  const changePassword = (e) => {
    e.preventDefault();
    notify.hide();
    if (newPassword != confirmPassword) {
      notify.show('New password and Confirm password does not match.', 'error');
      return;
    }
    setIsLoading(true);
    HTTP.put('/user/password', {
      old_password: password,
      password: newPassword
    }).then(response => {
      setIsLoading(false);
      notify.show('Successfully Changed', 'success');
      HTTP.post("/auth/logout", {})
        .then(response => {
          localStorage.removeItem('isra_access_token');
          location.reload();
        })
        .catch(err => {
          console.log(err);
        });
    }).catch(error => {
      setIsLoading(false);
      notify.show(error.response.data.message, 'error');
    });
  }



  const getSettings = () => {
    let query = { where: { "name": { "$ne": "payment_mode" } } };
    HTTP.get('/setting', { params: query }, {
    }).then(response => {
      if (response.data) {
        setData(response.data.data);
      }
    }).catch(error => {
      console.log(error);
    });
  }

  const getStripeMode = () => {
    let query = { where: { "name": "payment_mode" } };
    HTTP.get('/setting/find', { params: query }, {
    }).then(response => {
      if (response.data) {
        setstripeMode(response.data.data.setting.value);
      }
    }).catch(error => {
      console.log(error);
    });
  }

  const getStates = () => {
    let query = { where: { tax_percent: null }, limit: -1 };
    HTTP.get('/state', { params: query }, {
    }).then(response => {
      if (response.data) {
        setStates(response.data.data);
      }
    }).catch(error => {
      console.log(error);
    });
  }

  const getStatesWithTax = () => {
    let query = { where: { tax_percent: { $ne: null } }, limit: -1 };
    HTTP.get('/state', { params: query }, {
    }).then(response => {
      if (response.data) {
        setTaxStates(response.data.data);
      }
    }).catch(error => {
      console.log(error);
    });
  }

  const setPaymentSettingData = (field, value) => {
    let updatevalues = paymentSetting;
    updatevalues[field] = value;
    setpaymentSetting(updatevalues);
    setReload(reload == true ? false : true);
  }

  const updateSetting = (e, field) => {
    e.preventDefault();
    let updateJson = paymentSetting[field];
    HTTP.put('/setting/' + updateJson.id, { value: updateJson.value }, {
    }).then(response => {
      notify.show('Successfully Updated', 'success');
      updateJson.edit = false;
      setPaymentSettingData(field, updateJson);
    }).catch(error => {
      console.log(error);
      updateJson.edit = false;
      setPaymentSettingData(field, updateJson);
      notify.show(error.response.data.message || 'Something went wrong. Please try again.', 'error');
    });
  }

  const updateState = (e) => {
    e.preventDefault();
    HTTP.put('/state/' + state, { tax_percent: parseFloat(taxPercent) }, {
    }).then(response => {
      setShowModal(false);
      notify.show('Successfully Updated', 'success');
      setReload(reload == true ? false : true);
    }).catch(error => {
      setShowModal(false);
      console.log(error);
      setReload(reload == true ? false : true);
    });
  }

  const setShowWarningModalData = (modal, id) => {
    setShowWarningModal(modal);
    setDeleteStateId(id);
  }

  const setShowEditModalData = (modal, id, taxPercent) => {
    setState(id);
    setTaxPercent(taxPercent);
    setEditMode(true);
    setShowModal(modal);
    setReload(reload == true ? false : true);
  }

  const setShowModalData = () => {
    setState(null);
    setTaxPercent(null);
    setEditMode(false);
    setShowModal(true);
  }

  const deleteState = (e) => {
    e.preventDefault();
    HTTP.put('/state/' + deleteStateId, { tax_percent: null }, {
    }).then(response => {
      setShowWarningModal(false);
      notify.show('Successfully Deleted', 'success');
      setReload(reload == true ? false : true);
    }).catch(error => {
      setShowWarningModal(false);
      console.log(error);
      setReload(reload == true ? false : true);
    });
  }

  React.useEffect(() => {
    getSettings();
    getStripeMode();
    getStates();
    getStatesWithTax();
  }, [reload]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="mb-0 mb-3 d-flex p-0">
              <Card.Body className="border-top">
                <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                  <Tab eventKey="home" title="Account">
                    <Row>
                      <Col md="12">
                        <div className="p-4">
                          <Row className="mt-5">
                            <Col md="6" lg="6" xl="5">
                              <Form onSubmit={(e) => changePassword(e)}>
                                <h5 className="mb-3 font-weight-bold">
                                  Change Password
                                </h5>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicPassword"
                                >
                                  <Form.Label>Current Password</Form.Label>
                                  <Form.Control
                                    type="password"
                                    placeholder="Current Password"
                                    minlength="6"
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                  />
                                </Form.Group>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicPassword"
                                >
                                  <Form.Label>New Password</Form.Label>
                                  <Form.Control
                                    type="password"
                                    placeholder="New Password"
                                    minlength="6"
                                    onChange={(e) => setnewPassword(e.target.value)}
                                    required
                                  />
                                </Form.Group>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicPassword"
                                >
                                  <Form.Label>Confirm Password</Form.Label>
                                  <Form.Control
                                    type="password"
                                    placeholder="Confirm Password"
                                    minlength="6"
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                  />
                                </Form.Group>
                                <React.Fragment>
                                  {!isLoading ?
                                    <Button className="btn-fill" variant="primary" type="submit">Save Settings</Button>
                                    :
                                    <Button className="btn-fill" variant="primary" disabled type="button">Loading...</Button>
                                  }
                                </React.Fragment>
                              </Form>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <div className=""></div>
                  </Tab>
                  <Tab eventKey="payments" title="Payments" className="py-4">
                    <Row>
                      <Col md="12">
                        <div className="p-4">
                          <h5 className="mb-1 font-weight-bold">
                            Payment Settings
                          </h5>
                          <p>
                            Payment calculation will affect when the point of
                            you change the settings.
                          </p>
                        </div>
                      </Col>
                      <Col md="12" lg="12" xl="10">
                        {data.settings.map((setting, key) => {
                          return (
                            <div className="p-4">
                              <div className="border-bottom py-2">
                                <Row className="my-0 align-items-center">
                                  <Col md="4 ">{setting.display_name}</Col>
                                  <Col md="4 font-italic text-sm d-flex align-items-center">
                                    {setting.updated_at ? "Last modified on " + moment(setting.updated_at).format('Do MMMM YYYY h:mm A') + " " : ""}
                                  </Col>
                                  <Col md="4">
                                    <Form onSubmit={(e) => updateSetting(e, setting.name)}>
                                      <Row className="my-0 align-items-center">
                                        <Col xs="6 text-right font-weight-bold">
                                          <Form.Control
                                            type={setting.name == 'admin_email' ? "email" : "number"}
                                            placeholder={setting.display_name}
                                            minlength={setting.name == 'admin_email' ? "0" : "6"}
                                            hidden={!paymentSetting[setting.name].edit}
                                            value={paymentSetting[setting.name].value}
                                            max={setting.name == 'admin_commission' || setting.name == 'cancellation_fee' ? "100" : ""}
                                            min={setting.name == 'admin_commission' || setting.name == 'cancellation_fee' ? "1" : ""}
                                            step="any"
                                            onChange={(e) => setPaymentSettingData(setting.name, { id: setting.id, value: e.target.value, edit: true })}
                                            required
                                          />
                                          <span hidden={paymentSetting[setting.name].edit}>{setting.name == 'admin_email' ? setting.value : setting.name == 'admin_commission' || setting.name == 'cancellation_fee' ? setting.value + '%' : '$' + parseFloat(setting.value).toLocaleString()}</span>
                                        </Col>
                                        <Col xs="6 text-right">
                                          {!paymentSetting[setting.name].edit ?
                                            <button className="btn btn-sm btn-outline-primary" type="button" onClick={(e) => { setPaymentSettingData(setting.name, { id: setting.id, value: setting.value, edit: true }) }}>
                                              <FontAwesome
                                                name="pencil-alt"
                                                size="sm"
                                              />
                                            </button>
                                            :
                                            <Button className="btn btn-sm btn-outline-primary" type="submit">
                                              <FontAwesome
                                                name="save"
                                                size="sm"
                                              />
                                            </Button>
                                          }
                                        </Col>
                                      </Row>
                                    </Form>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          );
                        })}
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <div className="p-4">
                          <h5 className="mb-1 font-weight-bold">Service Tax</h5>
                          <p>
                            Add state and set a percentage value that is applicable.
                          </p>
                          <Button
                            className="btn btn-fill"
                            variant="primary"
                            onClick={() => setShowModalData()}
                          >
                            Add New State
                          </Button>
                        </div>
                      </Col>
                      <Col md="10" lg="6" xl="5">
                        {taxStates.states.map((state, key) => {
                          return (
                            <div className="px-4 pb-0">
                              <div className="border-bottom py-2">
                                <Row className="my-0 align-items-center">
                                  <Col md="4 ">{state.name} </Col>
                                  <Col md="8">
                                    <Row className="my-0 align-items-center">
                                      <Col xs="6 text-right font-weight-bold">
                                        {state.tax_percent}% ({state.code})
                                      </Col>
                                      <Col xs="6 text-right">
                                        <ButtonGroup aria-label="" className="">
                                          <Button
                                            variant="secondary"
                                            className="btn btn-sm btn-outline-primary"
                                            onClick={(e) => setShowEditModalData(true, state.id, state.tax_percent)}
                                          >
                                            {" "}
                                            <FontAwesome
                                              name="pencil-alt"
                                              size="sm"
                                            />
                                          </Button>
                                          <Button
                                            variant="secondary"
                                            className="btn btn-sm btn-outline-primary"
                                            onClick={(e) => setShowWarningModalData(true, state.id)}
                                          >
                                            {" "}
                                            <FontAwesome
                                              name="trash-alt text-red"
                                              size="sm"
                                            />
                                          </Button>
                                        </ButtonGroup>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          );
                        })}
                        <Modal
                          className="modal-mini modal-primary"
                          show={showWarningModal}
                          onHide={() => setShowWarningModal(false)}
                        >
                          <Modal.Header className="justify-content-center">
                            <div className="modal-profile">
                              <i className="nc-icon nc-bulb-63"></i>
                            </div>
                          </Modal.Header>
                          <Modal.Body className="text-center">
                            <p>Are you sure, Do you want to continue?</p>
                          </Modal.Body>
                          <div className="modal-footer">
                            <Button
                              className="btn-simple"
                              type="button"
                              variant="link"
                              onClick={(e) => setShowWarningModal(false)}
                            >
                              Close
                            </Button>
                            <Button
                              className="btn-simple"
                              type="button"
                              variant="danger"
                              onClick={(e) => deleteState(e)}
                            >
                              Continue
                            </Button>
                          </div>
                        </Modal>
                      </Col>
                    </Row>
                    <Row className="mt-5">
                      <Col md="12" lg="10" xl="8">
                        <div className="p-4">
                          <p className="mb-0">
                            <img
                              alt="..."
                              className="mr-2"
                              src={require("assets/img/Stripe.png").default}
                            ></img>
                          </p>
                          <p>Your stripe payment mode is {stripeMode.toLowerCase()} now</p>
                          <div className="border-bottom py-2">
                            <Row className="my-0 align-items-center">
                              <Col md="8 ">Stripe Payment mode</Col>
                              <Col md="10">
                                <Row className="my-0 align-items-center">
                                  <Col xs="12 text-right font-weight-bold">
                                    <FontAwesome
                                      name="circle text-green"
                                      size="md"
                                      className="mr-2"
                                    />
                                    {stripeMode}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Tab>
                </Tabs>
                <Modal
                  className=""
                  show={showModal}
                  onHide={() => setShowModal(false)}
                >
                  <Modal.Header className="justify-content-center border-bottom pb-3">
                    <div className="d-flex align-items-center w-100">
                      <h5 className="font-weight-bold m-0">{editMode ? 'Update' : 'Add'} Service Tax</h5>

                      <FontAwesome
                        name="times-circle"
                        size="lg"
                        className="ml-auto"
                        onClick={() => setShowModal(false)}
                      />
                    </div>
                  </Modal.Header>
                  <Form onSubmit={(e) => { updateState(e) }}>
                    <Modal.Body>
                      <div className="px-4">
                        <h5 className="font-weight-bold mb-1">Select State</h5>
                        <p className="text-muted text-sm">
                          Select the state where the tax is applicable.
                        </p>
                        <div>
                          <InputGroup className="mb-3">
                            <FormControl as="select" value={state} onChange={(e) => { setState(e.target.value) }} disabled={editMode} required>
                              <option selected value="" disabled>Select state</option>
                              {editMode ?
                                taxStates.states.map((state, key) => {
                                  return (
                                    <option value={state.id}>{state.name}</option>
                                  )
                                })
                                :
                                states.states.map((state, key) => {
                                  return (
                                    <option value={state.id}>{state.name}</option>
                                  )
                                })}
                            </FormControl>
                          </InputGroup>
                        </div>
                      </div>
                      <div className="px-4">
                        <h5 className="font-weight-bold mb-1">Tax Percentage</h5>
                        <p className="text-muted text-sm">
                          Set the tax percentage value.
                        </p>
                        <div>
                          <InputGroup className="mb-3">
                            <FormControl
                              placeholder="Enter the tax value"
                              aria-describedby="basic-addon2"
                              type="number"
                              max="100"
                              min="1"
                              step="any"
                              value={taxPercent}
                              onChange={(e) => { setTaxPercent(e.target.value) }}
                              required
                            />
                            <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                          </InputGroup>
                        </div>
                      </div>
                    </Modal.Body>
                    <div className="modal-footer d-flex justify-content-center pb-5">
                      <Button
                        className="btn btn-fill btn-block mx-auto"
                        variant="primary"
                        type="submit"
                        style={{ minWidth: 200 }}
                      >
                        Done
                      </Button>
                    </div>
                  </Form>
                </Modal>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Settings;
