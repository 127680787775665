import axios from 'axios';
import { history } from '../routes';

export const HTTP = axios.create({
  baseURL: "https://api.isratheapp.com/",
  // baseURL: "http://web.newagesme.com:3995/",
  // baseURL: "http://devapi.isratheapp.com/",
  // baseURL: "http://qaapi.isratheapp.com/",
  // baseURL: "http://localhost:3000/",
  headers: {
    'authorization': localStorage.getItem('isra_access_token'),
    'Content-Type': 'application/json'
  }
})

// Add a request interceptor
HTTP.interceptors.request.use(function (config) {
  HTTP.defaults.headers.authorization = localStorage.getItem('isra_access_token');
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
HTTP.interceptors.response.use(function (response) {
  if (response.data.data && response.data.data.token) {
    let accessToken = "Bearer " + response.data.data.token;
    HTTP.defaults.headers.authorization = accessToken;
    localStorage.setItem('isra_access_token', accessToken);
  }
  return response;
}, function (error) {
  if (error.response.status == 401) {
    delete HTTP.defaults.headers.authorization;
    localStorage.removeItem('isra_access_token');
    history.push('/');
  }
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});