/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { createHashHistory } from 'history';
import UserProfile from "views/UserProfile.js";
import Customers from "views/Customers";
import Stylists from "views/Stylists";
import StylistProfile from "views/StylistProfile";
import Announcements from "views/Announcements";
import TextMessaging from "views/Texts";
import Appointments from "views/Appointments";
import Payments from "views/Payments";
import Services from "views/Services";
import WIP from "views/WIP";
import Login from "views/Login";
import Photos from 'views/Photos';
import Reviews from 'views/Reviews';
import Settings from 'views/Settings';
import Dashboard from 'views/Dashboard';
import AppointmentDetail from 'views/AppointmentDetail';
import Notifications from 'views/Notifications';
import Coupons from 'views/Coupons';
const sidebarRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/customers",
    name: "Customers",
    component: Customers,
    layout: "/admin",
  },
  {
    path: "/stylists",
    name: "Stylists",
    component: Stylists,
    layout: "/admin",
  },
  {
    path: "/appointments",
    name: "Appointments",
    component: Appointments,
    layout: "/admin",
  },
  {
    path: "/services",
    name: "Available Services",
    component: Services,
    layout: "/admin",
  },
  {
    path: "/payments",
    name: "Payment Report",
    component: Payments,
    layout: "/admin",
  },
  {
    path: "/announcements",
    name: "Announcements",
    component: Announcements,
    layout: "/admin",
  },
  {
    path: "/messaging",
    name: "Text Messaging",
    component: TextMessaging,
    layout: "/admin",
  },
  {
    path: "/coupons",
    name: "Coupons",
    component: Coupons,
    layout: "/admin",
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    layout: "/admin",
  }
];

const loginRoutes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    layout: "/login",
  },
];

const dashboardRoutes = [
  {
    path: "/customer/profile/:id",
    name: "Customer Profile",
    component: UserProfile,
    layout: "/admin",
  },
  {
    path: "/stylist/profile/:id/gallery",
    name: "Stylist Gallery",
    component: Photos,
    layout: "/admin",
  },
  {
    path: "/stylist/profile/:id/reviews",
    name: "Stylist Reviews",
    component: Reviews,
    layout: "/admin",
  },
  {
    path: "/stylist/profile/:id",
    name: "Stylist Profile",
    component: StylistProfile,
    layout: "/admin",
  },
  {
    path: "/appointment/:id",
    name: "Appointment Detail",
    component: AppointmentDetail,
    layout: "/admin",
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
    layout: "/admin",
  },
];
const history = createHashHistory();
export { sidebarRoutes, dashboardRoutes, loginRoutes, history };
