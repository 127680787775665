import React from "react";
var FontAwesome = require("react-fontawesome");
// react-bootstrap components
import {
  Card,
  Table,
  Container,
  Row,
  Col
} from "react-bootstrap";
import { HTTP } from '../packages/axios';
import moment from 'moment-timezone';
import { Link } from "react-router-dom";

function Dashboard() {
  const [orderData, setOrderData] = React.useState({ orders: [], count: 0, last_week_count: 0 });
  const [revenueData, setRevenueData] = React.useState({ lifetime_total: 0, last_week_payout: 0 });
  const [topProviders, setTopProviders] = React.useState({ providers: [] })

  const getAppointments = () => {
    let query = {};
    query.sort = '[["created_at", "DESC"]]';
    HTTP.get('/order/all', { params: query }, {
    }).then(response => {
      if (response.data) {
        setOrderData(response.data.data);
      }
    }).catch(error => {
      console.log(error);
    });
  }

  const getRevenue = () => {
    HTTP.get('/payments/revenue', {}, {
    }).then(response => {
      if (response.data) {
        setRevenueData(response.data.data);
      }
    }).catch(error => {
      console.log(error);
    });
  }

  const getTopProviders = () => {
    HTTP.get('/user/stylist/stats', {}, {
    }).then(response => {
      if (response.data) {
        console.log(response.data.data);
        setTopProviders(response.data.data);
      }
    }).catch(error => {
      console.log(error);
    });
  }

  React.useEffect(() => {
    getRevenue();
    getAppointments();
    getTopProviders();
  }, [])

  return (
    <>
      <Container fluid>
        <Row className="d-flex flex-row">
          <Col md="8">
            <Row>
              <Col lg="6" sm="6">
                <Card className="card-stats">
                  <Card.Body>
                    <Row>
                      <Col xs="5">
                        <div className="icon-big text-center">
                          <img
                            alt="..."
                            className="icon-summery"
                            src={require("assets/img/growth.png").default}
                          ></img>
                        </div>
                      </Col>
                      <Col xs="7">
                        <div className="numbers">
                          <p className="card-category text-uppercase">
                            Total Revenue
                          </p>
                          <Card.Title as="h4">${revenueData.lifetime_total}</Card.Title>
                          <p className="text-small">Last week Payment - ${revenueData.last_week_payout}</p>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="6" sm="6">
                <Card className="card-stats">
                  <Card.Body>
                    <Row>
                      <Col xs="5">
                        <div className="icon-big text-center">
                          <img
                            alt="..."
                            className="icon-summery"
                            src={require("assets/img/scissors.png").default}
                          ></img>
                        </div>
                      </Col>
                      <Col xs="7">
                        <div className="numbers">
                          <p className="card-category text-uppercase">
                            Total BOOKINGS
                          </p>
                          <Card.Title as="h4">{Math.abs(orderData.count) > 999 ? Math.sign(10000000000000) * ((Math.abs(orderData.count) / 1000).toFixed(1)) + 'k' : Math.sign(orderData.count) * Math.abs(orderData.count)}</Card.Title>
                          <p className="">Last week Bookings - {Math.abs(orderData.last_week_count) > 999 ? Math.sign(10000000000000) * ((Math.abs(orderData.last_week_count) / 1000).toFixed(1)) + 'k' : Math.sign(orderData.last_week_count) * Math.abs(orderData.last_week_count)}</p>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Card className="mb-0 table-card">
                  <Card.Header>
                    <Card.Title as="h4">Latest Appointments</Card.Title>
                    <p className="card-category">{orderData.orders.length} Appointments </p>
                  </Card.Header>
                  <Card.Body className="table-full-width table-responsive px-0">
                    <Table className="table-hover table-bordered table-type01">
                      <thead>
                        <tr>
                          <th className="border-0">#</th>
                          <th className="border-0">Booking ID</th>
                          <th className="border-0">Amount</th>
                          <th className="border-0">Customer</th>
                          <th className="border-0">Stylist</th>
                          <th className="border-0">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderData.orders.map((order, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              <Link to={"/admin/appointment/" + order.id}>#ISRA{order.id}</Link>
                            </td>
                            <td className="text-bold">{'$' + parseFloat(order.order_amount).toLocaleString()}</td>
                            <td className="font-weight-bold">
                              <a className="td-user-thumb">
                                <img
                                  alt="..."
                                  className="user-40"
                                  src={order.customer.image ? order.customer.image : require("assets/img/faces/face-0.jpg").default}
                                ></img>
                                <p> <Link to={"/admin/customer/profile/" + order.customer.id}>{order.customer.full_name}</Link> </p>
                              </a>
                            </td>

                            <td className="font-weight-bold">
                              <a className="td-user-thumb">
                                <img
                                  alt="..."
                                  className="user-40"
                                  src={order.provider.image ? order.provider.image : require("assets/img/faces/face-0.jpg").default}
                                ></img>
                                <p> <Link to={"/admin/stylist/profile/" + order.provider.id}>{order.provider.full_name}</Link> </p>
                              </a>
                            </td>
                            <td>
                              {order.order_status == 'CANCELLED' ?
                                <span className="badge badge-pending" title={order.remarks}>
                                  <FontAwesome
                                    className="mr-1"
                                    name="times"
                                  />
                                  {order.order_status.charAt(0) + order.order_status.slice(1).toLowerCase().replace('_', ' ')}
                                </span>
                                :
                                <span className="badge badge-warning badge-active">
                                  <FontAwesome
                                    className="mr-1"
                                    name="check"
                                    size="sm"
                                  />
                                  {order.order_status.charAt(0) + order.order_status.slice(1).toLowerCase().replace('_', ' ')}
                                </span>
                              }
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                  <Card.Footer className="text-center">
                    <Link to={"/admin/appointments/"}>
                      View all <span>appointments</span>
                    </Link>
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md="4">
            <Card className="h-100 table-card">
              <Card.Header>
                <Card.Title as="h4">TOP STYLISTS</Card.Title>
                <div className="d-flex justify-content-between">
                  <p className="card-category">{topProviders.stylists ? topProviders.stylists.length : 0} Stylists </p>
                </div>
              </Card.Header>
              <Card.Body className="p-0">
                {topProviders.stylists && topProviders.stylists.map((provider, index) => (
                  <div className="stylist_list">
                    <img
                      alt="..."
                      className="user-60"
                      src={provider.image ? provider.image : require("assets/img/faces/face-0.jpg").default}
                    ></img>
                    <div>
                      <Link classname="username" to={"/admin/stylist/profile/" + provider.id} style={{ textDecoration: 'none', color: '#000000' }}>{provider.full_name}</Link>
                      <p>{`${provider.city},  ${provider.state}`}</p>
                      <p className="d-flex align-items-center">
                        <FontAwesome className="mr-1" name="star" size="sm" />
                        {provider['reviews.rating']}
                      </p>
                    </div>
                  </div>
                ))}
              </Card.Body>
              <Card.Footer className="text-center">
                <Link to={"/admin/stylists/"}>
                  View all <span>stylists</span>
                </Link>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
