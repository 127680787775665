import React from "react";
var FontAwesome = require("react-fontawesome");
// react-bootstrap components
import {
  Card,
  Container,
  Row,
  Col,
  Media,
  Button,
  Tooltip,
  OverlayTrigger
} from "react-bootstrap";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer
} from "react-google-maps";
import { notify } from 'react-notify-toast';
import { HTTP } from '../packages/axios';
import { Link, useParams } from "react-router-dom";
import moment from 'moment-timezone';

function AppointmentDetail() {

  const [directions, setDirections] = React.useState(null);
  const [appointment, setAppointment] = React.useState({ order: null });
  const { id } = useParams();

  const getAppointment = () => {
    HTTP.get('/order/' + id, {}, {
    }).then(response => {
      if (response.data) {
        // console.log(response.data);
        setAppointment(response.data.data);
        initializeMap(response.data.data.order);
      }
    }).catch(error => {
      console.log(error);
    });
  }

  const initializeMap = (orderData) => {

    const directionsService = new google.maps.DirectionsService();

    const origin = { lat: parseFloat(orderData.provider.latitude), lng: parseFloat(orderData.provider.longitude) };
    const destination = { lat: parseFloat(orderData.service_latitude), lng: parseFloat(orderData.service_longitude) };

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          setDirections(result)
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );

  }

  const renderMap = () => {
    if (appointment.order) {
      const GoogleMapExample = withGoogleMap(props => (
        <GoogleMap
          defaultCenter={{ lat: parseFloat(appointment.order.customer.latitude), lng: parseFloat(appointment.order.customer.longitude) }}
          defaultZoom={13}
        >
          <DirectionsRenderer
            directions={directions}
            options={{
              polylineOptions: {
                strokeOpacity: 1,
                strokeColor: '#000000',
              }
            }}
          />
        </GoogleMap>
      ));

      return (
        <GoogleMapExample
          containerElement={<div style={{ height: "100vh" }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      );
    }
  }

  const renderStars = (count) => {
    let stars = [];
    for (var i = 0; i < count; i++) {
      stars.push(
        <FontAwesome
          className="mr-1 text-yellow"
          name="star"
          size="sm"
        />
      );
    }
    return stars;
  }

  const deleteReview = (id) => {
    var deletePrompt = window.confirm("Are you sure to delete?");
    if (!!deletePrompt) {
      HTTP.delete('/user/admin/reviews/' + id, {}, {
      }).then(response => {
        if (response.data) {
          notify.show("Review deleted.");
          getAppointment();
        }
      }).catch(error => {
        notify.show("Something went wrong !!!. Please try again.");
        console.log(error);
      });
    }
  }

  React.useEffect(() => {
    getAppointment();
  }, [])

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="6">
            <Card className="mb-0 mb-3 d-flex">
              <Card.Header className="d-flex align-items-center justify-content-between border-bottom-1 pb-4">
                <div>
                  <div className="d-flex align-items-center w-100">
                    <h3 className="my-2">Appointment #{appointment.order ? appointment.order.id : ''}</h3> &nbsp;
                    {appointment.order && appointment.order.order_status == 'CANCELLED' ?
                      <span className="badge badge-pending">
                        <FontAwesome
                          className="mr-1"
                          name="times"
                        />
                        {appointment.order ? appointment.order.order_status.charAt(0) + appointment.order.order_status.slice(1).toLowerCase().replace('_', ' ') : ''}
                      </span>
                      :
                      <span className="badge badge-warning badge-active">
                        <FontAwesome
                          className="mr-1"
                          name="check"
                          size="sm"
                        />
                        {appointment.order ? appointment.order.order_status.charAt(0) + appointment.order.order_status.slice(1).toLowerCase().replace('_', ' ') : ''}
                      </span>
                    }
                  </div>
                  <div className="">{appointment.order ? moment(appointment.order.created_at).format('MMMM D, YYYY, dddd hh:mm A') : ''}</div>
                </div>
                <div className="ml-auto">
                  <Link to="/admin/appointments">
                    <Button className="btn btn-outnile">
                      <FontAwesome
                        className="mr-1"
                        name="arrow-left"
                        size="sm"
                      />
                      Back
                    </Button>
                  </Link>
                </div>
              </Card.Header>
              <Card.Body className="px-0 pb-0 border-top">
                <div className="mx-4">
                  <div className="my-3">
                    <div className="font-weight-bold text-muted">
                      Services Booked
                    </div>
                    <div className="border-bottom">
                      <ul className="my-3 mx-0 order-summary list-styled">
                        {appointment.order && appointment.order.order_services.map((orderService, index) => (
                          <li className="d-flex align-items-center justify-content-between">
                            <span>{orderService.service.service_name}</span> <span className="font-weight-bold">{'$' + parseFloat(orderService.service_amount).toLocaleString()}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="border-bottom">
                      <ul className="my-3 mx-0 order-summary">
                        <li className="d-flex align-items-center justify-content-between">
                          <span>Base fare</span> <span className="font-weight-bold">{appointment.order ? '$' + parseFloat(+appointment.order.transactions[0].base_fare + +appointment.order.transactions[0].base_fare_discount).toLocaleString() : ''}</span>
                        </li>
                        <li className="d-flex align-items-center justify-content-between">
                          <span>Transportation Fee</span> <span className="font-weight-bold">{appointment.order ? '$' + parseFloat(appointment.order.transactions[0].transportation_amount).toLocaleString() : ''}</span>
                        </li>
                        <li className="d-flex align-items-center justify-content-between">
                          <span>Tax Amount </span> <span className="font-weight-bold">{appointment.order ? '$' + parseFloat(appointment.order.transactions[0].tax_amount).toLocaleString() : ''}</span>
                        </li>
                        <li className="d-flex align-items-center justify-content-between">
                          <span>Stripe Commission </span> <span className="font-weight-bold">{appointment.order ? '$' + parseFloat(appointment.order.transactions[0].handling_charge).toLocaleString() : ''}</span>
                        </li>
                        {appointment.order && appointment.order.coupon && <li className="d-flex align-items-center justify-content-between">
                          <span style={{ color: "red" }}>Coupon Discount{" "}
                            <span style={{ color: "red" }}>
                              <OverlayTrigger
                                delay={{ hide: 450, show: 300 }}
                                overlay={
                                  <Tooltip style={{ maxWidth: 900 }}>
                                    <div className="tooltip-content">
                                      <div style={{ textAlign: 'left' }}>Coupon Name: {appointment.order.coupon.coupon_name}</div>
                                      <div style={{ textAlign: 'left' }}>Offer Percentage: {appointment.order.coupon.discount} %</div>
                                      <div style={{ textAlign: 'left' }}>Start Date: {appointment.order.coupon.coupon_start_date}</div>
                                      <div style={{ textAlign: 'left' }}>End Date: {appointment.order.coupon.coupon_expiry_date}</div>
                                    </div>
                                  </Tooltip>

                                }
                                placement="bottom"
                              >
                                <span><b>({appointment.order.coupon.coupon_code})</b></span>
                              </OverlayTrigger>


                            </span></span> <span className="font-weight-bold" style={{ color: "red" }}>{appointment.order ? '- $' + parseFloat(appointment.order.transactions[0].initial_service_amount - appointment.order.transactions[0].service_amount + +appointment.order.transactions[0].base_fare_discount).toLocaleString() : ''}</span>
                        </li>}
                      </ul>
                    </div>
                    <div className="d-flex align-items-center justify-content-between font-weight-bold py-3">
                      <span className="text-md text-sm">TOTAL AMOUNT</span> <span className=" text-md" >{appointment.order ? '$' + parseFloat(appointment.order.transactions[0].collected_amount).toLocaleString() : ''}</span>
                    </div>

                  </div>

                </div>
                <div className="border-top p-4">
                  <div className="font-weight-bold text-muted pb-2">
                    Services Date and Time
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>{appointment.order ? moment(appointment.order.service_at).format('MMMM D, YYYY, dddd hh:mm A') : ''}</div>
                    <div className="service-icon">
                      {appointment.order && appointment.order.service_location == "MOBILE" ?
                        <React.Fragment>
                          <span className="at-home"></span>Home
                        </React.Fragment>
                        :
                        <React.Fragment>
                          <span className="at-salon"></span>Salon
                        </React.Fragment>
                      }
                    </div>
                  </div>
                </div>
                <div className="border-top px-4">
                  <div className="row">
                    <div className="col-md-6 border-right pt-4">
                      <div className="font-weight-bold text-muted pb-2">
                        Stylist Details
                      </div>
                      <Media className="mb-2 py-2">
                        <img
                          alt="..."
                          className="avatar-70 mr-4"
                          src={appointment.order && appointment.order.provider.image ? appointment.order.provider.image : require("assets/img/faces/face-0.jpg").default}
                        ></img>
                        <Media.Body>
                          <div className="d-flex">
                            <div>
                              <h5 className="mb-0 font-weight-bold">
                                {appointment.order ? appointment.order.provider.full_name : ''}
                              </h5>
                              <p className="mb-1">{appointment.order ? appointment.order.provider.address : ''}</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center mb-3">
                            <FontAwesome
                              className="mr-1 text-yellow"
                              name="star"
                              size="sm"
                            />
                            <span>{appointment.order && appointment.order.provider_avg_rating ? appointment.order.provider_avg_rating : 0}</span>
                          </div>
                        </Media.Body>
                      </Media>
                    </div>
                    <div className="col-md-6 pt-4">
                      <div className="font-weight-bold text-muted pb-2">
                        Customer Details
                      </div>
                      <Media className="mb-2 py-2">
                        <img
                          alt="..."
                          className="avatar-70 mr-4"
                          src={appointment.order && appointment.order.customer.image ? appointment.order.customer.image : require("assets/img/faces/face-0.jpg").default}
                        ></img>
                        <Media.Body>
                          <div className="d-flex">
                            <div>
                              <h5 className="mb-0 font-weight-bold">
                                {appointment.order ? appointment.order.customer.full_name : ''}
                              </h5>
                              <p className="mb-1">{appointment.order ? appointment.order.customer.address : ''}</p>
                            </div>
                          </div>
                        </Media.Body>
                      </Media>
                    </div>
                  </div>
                </div>

              </Card.Body>
            </Card>

            <Card>
              <Card.Body className="py-0">
                <div className="row border-bottom">
                  <div className="col-md-6 border-right py-4">
                    <div className="font-weight-bold text-muted pb-2">
                      Payment to Stylist
                    </div>
                    <div className="text-md font-weight-bold">{appointment.order ? '$' + parseFloat(appointment.order.transactions[0].destination_amount).toLocaleString() : ''}</div>
                    <ul className="my-3 mx-0 order-summary">
                      <li className="d-flex align-items-center justify-content-between">
                        {(appointment.order && appointment.order.transactions && appointment.order.transactions[0].initial_service_amount && appointment.order.transactions[0].base_fare_discount) ?
                          <span>Stylist Service Fee
                            {appointment.order ?
                              ' ($' + parseFloat(parseFloat(appointment.order.transactions[0].destination_amount) + parseFloat(appointment.order.transactions[0].initial_service_amount) - parseFloat(appointment.order.transactions[0].transportation_amount)).toLocaleString() + ' - $' + parseFloat(parseFloat(appointment.order.transactions[0].initial_service_amount)).toLocaleString() + ')'
                              : ''}
                          </span> : <span>Stylist Service Fee
                            {appointment.order ?
                              ' ($' + parseFloat(parseFloat(appointment.order.transactions[0].destination_amount) + parseFloat(appointment.order.transactions[0].service_amount) - parseFloat(appointment.order.transactions[0].transportation_amount)).toLocaleString() + ' - $' + parseFloat(parseFloat(appointment.order.transactions[0].service_amount)).toLocaleString() + ')'
                              : ''}
                          </span>}
                        <span className="font-weight-bold">{appointment.order ? '$' + parseFloat(parseFloat(appointment.order.transactions[0].destination_amount) - parseFloat(appointment.order.transactions[0].transportation_amount).toFixed(2)).toLocaleString() : ''}</span>
                      </li>
                      <li className="d-flex align-items-center justify-content-between">
                        <span>Transportation Fee  </span> <span className="font-weight-bold">{appointment.order ? '$' + parseFloat(appointment.order.transactions[0].transportation_amount).toLocaleString() : ''}</span>
                      </li>

                    </ul>
                  </div>
                  <div className="col-md-6  py-4">
                    <div className="font-weight-bold text-muted pb-2">
                      Payment to Admin
                    </div>
                    <div className="text-md font-weight-bold">{appointment.order ? '$' + parseFloat(appointment.order.transactions[0].application_amount).toLocaleString() : ''}</div>
                    <ul className="my-3 mx-0 order-summary">
                      {(appointment.order && appointment.order.transactions && appointment.order.transactions[0].initial_service_amount && appointment.order.transactions[0].base_fare_discount) ?
                        <li className="d-flex align-items-center justify-content-between">
                          <span>Admin Commission
                            {appointment.order ?
                              ' (' + Math.round(parseFloat(parseFloat(appointment.order.transactions[0].initial_service_amount) /
                                (parseFloat(appointment.order.transactions[0].destination_amount) + parseFloat(appointment.order.transactions[0].initial_service_amount) - parseFloat(appointment.order.transactions[0].transportation_amount))
                              ) * 100) + '% of $' + parseFloat(parseFloat(appointment.order.transactions[0].destination_amount) + parseFloat(appointment.order.transactions[0].initial_service_amount) - parseFloat(appointment.order.transactions[0].transportation_amount)).toLocaleString() + ')'
                              : ''}
                          </span> <span className="font-weight-bold"> {appointment.order ? '$' + parseFloat(appointment.order.transactions[0].initial_service_amount).toLocaleString() : ''} </span>
                        </li> :
                        <li className="d-flex align-items-center justify-content-between">
                          <span>Admin Commission
                            {appointment.order ?
                              ' (' + parseFloat(parseFloat(appointment.order.transactions[0].service_amount) /
                                (parseFloat(appointment.order.transactions[0].destination_amount) + parseFloat(appointment.order.transactions[0].service_amount) - parseFloat(appointment.order.transactions[0].transportation_amount))
                              ) * 100 + '% of $' + parseFloat(parseFloat(appointment.order.transactions[0].destination_amount) + parseFloat(appointment.order.transactions[0].service_amount) - parseFloat(appointment.order.transactions[0].transportation_amount)).toLocaleString() + ')'
                              : ''}
                          </span> <span className="font-weight-bold"> {appointment.order ? '$' + parseFloat(appointment.order.transactions[0].service_amount).toLocaleString() : ''} </span>
                        </li>}
                      <li className="d-flex align-items-center justify-content-between">
                        <span>Base fare</span> <span className="font-weight-bold">{appointment.order ? '$' + parseFloat(+appointment.order.transactions[0].base_fare + +appointment.order.transactions[0].base_fare_discount).toLocaleString() : ''}</span>
                      </li>
                      <li className="d-flex align-items-center justify-content-between">
                        <span>Service Tax  </span> <span className="font-weight-bold"> {appointment.order ? '$' + parseFloat(appointment.order.transactions[0].tax_amount).toLocaleString() : ''}</span>
                      </li>
                      <li className="d-flex align-items-center justify-content-between">
                        <span>Stripe Commission    </span> <span className="font-weight-bold"> {appointment.order ? '$' + parseFloat(appointment.order.transactions[0].handling_charge).toLocaleString() : ''} </span>
                      </li>
                      {appointment.order && appointment.order.coupon && <li className="d-flex align-items-center justify-content-between">
                        <span style={{ color: "red" }}>Coupon Discount    </span> <span className="font-weight-bold" style={{ color: "red" }}> {appointment.order ? '- $' + parseFloat(appointment.order.transactions[0].initial_service_amount - appointment.order.transactions[0].service_amount + +appointment.order.transactions[0].base_fare_discount).toLocaleString() : ''} </span>
                      </li>}
                    </ul>
                  </div>

                </div>
                <div className="row">
                  <div className="col-12 pt-4">
                    <p className="font-weight-bold mb-2">Payment Status {appointment.order && appointment.order.transactions[0].refund_id ? <span className="text-red">Refunded</span> : <span className="text-green">Success</span>}</p>
                    <p>Transaction #{appointment.order ? appointment.order.transactions[0].id : ''} <br />
                      Card - XXX XXX XX{appointment.order ? appointment.order.transactions[0].card_last4 : ''} <br />
                      Transaction Id - {appointment.order ? appointment.order.transactions[0].transaction_id : ''}
                    </p>
                  </div>
                </div>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body hidden={appointment.order && !appointment.order.review && !appointment.order.transactions[1]}>
                <div className="row" hidden={appointment.order && !appointment.order.transactions[1]}>
                  <div className="col-12 font-weight-bold text-muted pb-2">
                    Tip Payment
                  </div>
                </div>
                <div className="row border-bottom" hidden={appointment.order && !appointment.order.transactions[1]}>
                  <div className="col-md-6">
                    <h5 className="font-weight-bold">{appointment.order && appointment.order.transactions[1] ? '$' + parseFloat(appointment.order.transactions[1].collected_amount).toLocaleString() : ''}</h5>
                  </div>
                  <div className="col-md-6">
                    <p>Transaction #{appointment.order && appointment.order.transactions[1] ? appointment.order.transactions[1].id : ''} <br />
                      Card - XXX XXX XX{appointment.order && appointment.order.transactions[1] ? appointment.order.transactions[1].card_last4 : ''}
                    </p>
                  </div>
                </div>
                <div className="row" hidden={appointment.order && !appointment.order.review}>
                  <div className="col-12 pt-4">
                    <Media >
                      <img
                        alt="..."
                        className="avatar-70 mr-4"
                        src={appointment.order && appointment.order.review && appointment.order.review.customer && appointment.order.review.customer.image ? appointment.order.review.customer.image : require("assets/img/faces/face-0.jpg").default}
                      ></img>
                      <Media.Body>
                        <div className="d-flex">
                          <div >
                            <h5 className="mb-0 font-weight-bold">
                              {appointment.order && appointment.order.review ? appointment.order.review.customer.full_name : ''}
                            </h5>
                            <p className="mb-1">{appointment.order && appointment.order.review ? appointment.order.review.customer.address : ''}</p>
                          </div>
                          <div className="ml-auto">
                            <Button className="btn btn-outnile"
                              onClick={(e) => { deleteReview(appointment.order.review.id) }}>
                              <FontAwesome
                                name="trash-alt"
                                size="sm"
                              />
                            </Button>
                          </div>
                        </div>
                        <div className="d-flex align-items-center mb-3">
                          {appointment.order && appointment.order.review ? renderStars(appointment.order.review.rating) : ''}
                        </div>
                        <div className="d-flex">
                          <p className="col-10 text-justify">
                            {appointment.order && appointment.order.review ? appointment.order.review.comments : ''}
                          </p>
                          <div className="text-muted ml-auto font-italic text-sm">
                            {appointment.order && appointment.order.review ? moment(appointment.order.review.created_at).fromNow() : ''}
                          </div>
                        </div>
                      </Media.Body>
                    </Media>
                  </div>
                </div>
              </Card.Body>
            </Card>

          </Col>
          <Col md="6" className="d-flex">
            <Card className="w-100">
              <Card.Body>
                <div className="row align-items-center border-bottom">
                  <div className="col-md-6 p-4">
                    <span className="font-weight-bold">Service Location</span>  <br />
                    {appointment.order ? appointment.order.service_address : ''}
                  </div>
                  <div className="col-md-6 text-md font-weight-bold text-right">
                    {appointment.order ? appointment.order.service_distance + ' Miles' : ''}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 p-4">
                    {renderMap()}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AppointmentDetail;
