import React from "react";
var FontAwesome = require("react-fontawesome");
// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Media
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { HTTP } from '../packages/axios';
import moment from 'moment-timezone';

function Reviews() {
  const { id } = useParams();
  const [data, setData] = React.useState({ reviews: [] });

  const getStylist = () => {
    let query = { where: { id } };
    HTTP.get('/user/find', { params: query }, {
    }).then(response => {
      if (response.data) {
        setData(response.data.data);
      }
    }).catch(error => {
      console.log(error);
    });
  }

  const renderStars = (count) => {
    let stars = [];
    for (var i = 0; i < count; i++) {
      stars.push(
        <FontAwesome
          className="mr-1 text-yellow"
          name="star"
          size="sm"
        />
      );
    }
    return stars;
  }

  React.useEffect(() => {
    getStylist();
  }, [])

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="mb-0 mb-3 d-flex">
              <Card.Header className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center w-100 pb-3">
                  <div>
                    <img
                      alt="..."
                      className="avatar-100 mr-4"
                      src={data.user && data.user.image ? data.user.image : require("assets/img/faces/face-0.jpg").default}
                    ></img>
                  </div>
                  <div>
                    <div className="d-flex align-items-center">
                      {data.user && data.user.active == 0 ? <span className="badge badge-rejected">
                        <FontAwesome className="mr-1" name="times" />
                        Blocked
                      </span> : <>{data.user && data.user.status == 0 && (
                        <span className="badge badge-pending">
                          <FontAwesome
                            className="mr-1"
                            name="pause-circle"
                          />
                          Pending
                        </span>
                      )}
                        {data.user && data.user.status == 1 && (
                          <span className="badge badge-active">
                            <FontAwesome className="mr-1" name="check" />
                            Approved
                          </span>
                        )}
                        {data.user && data.user.status == -1 && (
                          <span className="badge badge-rejected">
                            <FontAwesome className="mr-1" name="times" />
                            Rejected
                          </span>
                        )}
                        {data.user && data.user.status == -2 && (
                          <span className="badge badge-rejected">
                            <FontAwesome className="mr-1" name="times" />
                            Incomplete
                          </span>
                        )}</>}
                      <span className="font-weight-bold text-secondary p-2">
                        {data.user ? '#ISRA' + data.user.id : ''}
                      </span>
                    </div>
                    <h3 className="my-2">{data.user ? data.user.full_name : ''}</h3>
                    <div className="d-flex justify-content-center">
                      <div className="border-right pr-2">{data.user && data.user.phone ? '(' + data.user.phone_code + ') ' + data.user.phone : ''}</div>
                      <div className="border-right px-2">
                        {data.user ? data.user.email : ''}
                      </div>
                      <div className="pl-2">
                        {data.profile && data.profile.instagram ?
                          <a href={'https://www.instagram.com/' + data.profile.instagram.replace("instagram.com/", "").replace("@", "").replace("https://", "")} target="_blank">
                            <img
                              alt="..."
                              className="mr-2"
                              src={require("assets/img/Insta.png").default}
                            ></img>
                          </a>
                          : ''}
                        {data.profile && data.profile.facebook ?
                          <a href={'https://www.facebook.com/' + data.profile.facebook.replace("https://", "").replace("http://", "")} target="_blank">
                            <img
                              alt="..."
                              className="mr-2"
                              src={require("assets/img/facebook.png").default}
                            ></img>
                          </a>
                          : ''}
                      </div>
                    </div>
                  </div>
                  <div className="ml-auto">
                    <Link to={"/admin/stylist/profile/" + id}>
                      <Button className="btn btn-outnile">
                        <FontAwesome
                          className="mr-1"
                          name="arrow-left"
                          size="sm"
                        />
                        Back
                      </Button>
                    </Link>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className="px-4 border-top">
                <div className="d-flex mb-3">
                  <div className="d-flex align-items-center">
                    <h5 className="font-weight-bold mb-0">Reviews </h5>
                    <span className="ml-2">
                      <FontAwesome
                        className="mr-1 text-yellow"
                        name="star"
                        size="sm"
                      />
                      {data.reviews.length > 0 ? parseFloat((data.reviews.reduce((a, b) => +a + +b.rating, 0) / data.reviews.length).toFixed(2)) : 0}
                    </span>
                  </div>
                </div>
                <div className="mt-2">
                  {data.reviews.map((review, key) => {
                    return (
                      <Media className="mb-2 border-bottom py-2">
                        <img
                          alt="..."
                          className="avatar-70 mr-4"
                          src={review.customer.image ? review.customer.image : require("assets/img/faces/face-0.jpg").default}
                        ></img>
                        <Media.Body>
                          <div className="d-flex">
                            <div>
                              <h5 className="mb-0 font-weight-bold">
                                {review.customer.full_name}
                              </h5>
                              <p className="mb-1">{review.customer.address}</p>
                            </div>
                            <div className="text-muted ml-auto font-italic text-sm">
                              {moment(review.created_at).fromNow()}
                            </div>
                          </div>
                          <div className="d-flex align-items-center mb-3">
                            {renderStars(review.rating)}
                          </div>
                          <p>
                            {review.comments}
                          </p>
                        </Media.Body>
                      </Media>
                    );
                  })}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Reviews;
