import React from "react";
var FontAwesome = require("react-fontawesome");
// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Table,
  Row,
  Col,
  Form,
  Modal,
  Spinner,
  Popover, OverlayTrigger
} from "react-bootstrap";
import { HTTP } from '../packages/axios';
import moment from 'moment-timezone';
import { notify } from 'react-notify-toast';
import Pagination from 'react-bootstrap-4-pagination';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function Coupons() {
  const [coupons, setCoupons] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [error, setError] = React.useState('')
  const [status, setStatus] = React.useState('all')
  const [couponCodeError, setCouponCodeError] = React.useState('')
  const [couponNameError, setCouponNameError] = React.useState('')
  const [discountError, setDiscountError] = React.useState('')
  const [startError, setStartError] = React.useState('')
  const [expiryError, setExpiryError] = React.useState('')
  const [showModal, setShowModal] = React.useState(false);
  const [paginationConfig, setpaginationConfig] = React.useState({});
  const [perPageLimit, setPerPageLimit] = React.useState(10);
  const [broadcastCount, setBroadcastCount] = React.useState(0);
  const [discount, setDiscount] = React.useState('');
  const [couponName, setCouponName] = React.useState('')
  const [couponCode, setCouponCode] = React.useState('')
  const [startDate, setStartDate] = React.useState('')
  const [expiry, setExpiry] = React.useState('')
  const [deleteId, setDeleteId] = React.useState(null)
  const [modalDelete, setModalDelete] = React.useState(false)
  const [page, setPage] = React.useState(0)

  const createCoupon = (e) => {
    e.preventDefault();
    notify.hide();
    if (!couponName.replace(/\s{2,}/g, ' ').trim() || !couponCode.replace(/\s{2,}/g, ' ').trim()) {
      if (!couponName.replace(/\s{2,}/g, ' ').trim()) {
        setCouponNameError("*Name is required")
      }
      if (!couponCode.replace(/\s{2,}/g, ' ').trim()) {
        setCouponCodeError("*Code is required")
      }
    }
    if (!discount) {
      setDiscountError("*Discount is required")
    }
    if (!startDate) {
      setStartError("*Start Date is required")
    }
    if (!expiry) {
      setExpiryError("*End Date is required")
    }
    if (!expiry || !startDate || !discount || !couponCode || !couponName)
      return

    if (discountError) return


    setCouponCodeError('')
    setExpiryError('')
    setStartError('')
    setDiscountError('')
    setCouponNameError('')
    setIsLoading(true);
    HTTP.post(`/user/coupon`,
      {
        coupon_name: couponName.replace(/\s{2,}/g, ' ').trim(),
        coupon_code: couponCode.replace(/\s{2,}/g, ' ').trim(),
        discount,
        coupon_start_date: startDate,
        coupon_expiry_date: expiry
      }
    ).then(() => {
      getBroadcasts();
      setIsLoading(false);
      setCouponName('')
      setCouponCode('')
      setDiscount("")
      setStartDate("")
      setExpiry("")
      setShowModal(false)
      notify.show('Coupon created successfully', 'success');
    }).catch(error => {
      setIsLoading(false);
      // setShowModal(false)
      // setCouponName('')
      // setCouponCode('')
      // setDiscount("")
      // setStartDate("")
      // setExpiry("")
      notify.show(error.response.data.message || 'Something went wrong. Please try again.', 'error');
    });
  }

  const deleteBroadcast = (e) => {
    e.preventDefault();
    notify.hide();
    setIsDeleting(true)
    setIsLoading(true)
    HTTP.delete(`/user/coupons/${deleteId}`).then(() => {
      getBroadcasts();
      setIsDeleting(false)
      setModalDelete(false)
      setDeleteId(null)
      notify.show('Successfully deleted', 'success');
    }).catch(error => {
      setDeleteId(null)
      setIsDeleting(false)
      setModalDelete(false)
      notify.show('Something went wrong. Please try again.', 'error');
    });
  }

  const getBroadcasts = (offset) => {
    let query = {};
    if (status == 'all') query.where = {}
    else if (status == 'deleted') query.where = {
      deleted_at: {
        $ne: null
      }
    }
    else
      query.where = {
        status, deleted_at: {
          $eq: null
        }
      }
    query.limit = perPageLimit;
    query.offset = offset;
    query.sort = '[["created_at", "DESC"]]';
    HTTP.get('/user/coupons/admin', { params: query }, {
    }).then(response => {
      if (response.data) {
        setCoupons(response.data.data.coupons);
        setBroadcastCount(response.data.data.count)
        setIsLoading(false);
        if (response.data.data.coupons.length > 0) {
          setPage(offset)
          setpaginationConfig({
            totalPages: Math.ceil(response.data.data.count / response.data.data.limit),
            currentPage: Math.ceil((response.data.data.limit + response.data.data.offset) / response.data.data.limit),
            showMax: 5,
            size: "sm",
            threeDots: false,
            onClick: function (e, page) {
              e.preventDefault();
              getBroadcasts((page - 1) * response.data.data.limit);
            }
          })
        }
      }
    }).catch(error => {
      console.log(error);
    });
  }

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title >How to use emoji's ?</Popover.Title>
      <Popover.Content>
        <ul>
          <li><b>In Windows</b></li>
          ❖ Windows key + . (Period)
          <li><b>In Mac</b></li>
          ⌘ Command + Control + Space
        </ul>
      </Popover.Content >
    </Popover >
  );



  React.useEffect(() => {
    getBroadcasts();
  }, [status])

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <div className="p-4">
              <Row className="mt-1 justify-content-end">
                <Button className="btn-fill" variant="primary"
                  onClick={() => { setShowModal(true) }}> <span>{showModal ?
                    <Spinner size="sm" animation="border" className="align-text-top mr-2" hidden={!showModal} />
                    : <i className="nc-icon nc-simple-add align-text-top mr-2"></i>} {"  "} Create Coupon</span></Button>
              </Row>
            </div>
          </Col>
        </Row>
        <Modal backdrop='static'
          className="modal-mini modal-primary"
          show={modalDelete}
          onHide={() => setModalDelete(false)}
        >
          <Modal.Header className="justify-content-center">
            <div className="modal-profile">
              <i className="nc-icon nc-simple-remove" style={{ color: "red" }}></i>
            </div>
          </Modal.Header>
          <Modal.Body className="text-center">
            <p>Are you sure you want to delete?</p>
          </Modal.Body>
          <div className="modal-footer">
            <Button
              className="btn-simple"
              type="button"
              variant="link"
              disabled={isDeleting}
              onClick={(e) => {
                setModalDelete(false)
                setDeleteId(null)
              }}
            >
              Close
            </Button>
            <Button
              className="btn-simple"
              type="button"
              variant="danger"
              onClick={(e) => deleteBroadcast(e)}
            >
              Continue
            </Button>
          </div>
        </Modal>
        <Modal backdrop='static'
          className="modal-normal-announcements modal-primary"
          show={showModal}
          onHide={() => setShowModal(false)}
        >
          <Modal.Header className="justify-content-center">
            {/* <div className="modal-profile mt-3" style={{ transform: "scale(1.3)" }}>
              <i className="nc-icon nc-simple-add" style={{ color: "#3c5fee" }}></i>
            </div> */}
          </Modal.Header>
          <Modal.Body className=''>
            <Row>
              <Col md="12" >
                <div className="p-1">
                  <Row className="mt-1">
                    <Col md="12" >
                      <Form >
                        <h4 className="mb-3 font-weight-bold text-center">
                          Create Coupon
                        </h4>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicPassword"
                        >
                          <Form.Label className={couponNameError && "error-text"}>Coupon Name</Form.Label>
                          <Form.Control
                            type="input"
                            className={couponNameError && "error-border"}
                            placeholder="Enter Name"
                            minlength="6"
                            maxLength='200'
                            onChange={(e) => {
                              setCouponName(e.target.value.replace(/\s{2,}/g, ' '))
                              setCouponNameError('')
                            }}
                            value={couponName}
                            required
                          />

                          {couponNameError && <div className={couponNameError && "error-text"}>{couponNameError}</div >}
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="formBasicTitle"
                        >
                          <Form.Label className={couponCodeError && "error-text"}>Coupon Code {" "}
                            {!couponCode && <span className="text-sm" style={{ color: "#afb5be" }}>
                              {" "} <i>max 15 characters</i></span>}
                            {!!couponCode && <span className="text-sm" style={{ color: "#afb5be" }}>
                              {" "} <i>{15 - couponCode.replace(/\s{2,}/g, ' ').length} characters left</i></span>}
                          </Form.Label>
                          <Form.Control
                            type="input"
                            className={couponCodeError && "error-border"}
                            placeholder="Enter Code"
                            minlength="4"
                            maxLength='15'
                            onChange={(e) => {
                              setCouponCode(e.target.value.replace(/\s{2,}/g, ' '))
                              setCouponCodeError('')
                            }}
                            value={couponCode}
                            required
                          />
                          {couponCodeError && <div className={couponCodeError && "error-text"}>{couponCodeError}</div >}
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicTitle"
                        >
                          <Form.Label className={discountError && "error-text"}>Discount (Percentage) {" "}
                          </Form.Label>
                          <Form.Control
                            type="number"
                            step={1}
                            className={discountError && "error-border"}
                            placeholder="Enter Discount Percentage"
                            value={discount}
                            min={1}
                            max={80}
                            onChange={(e) => {
                              setDiscount(e.target.value)
                              setDiscountError(e.target.value <= 80 ? '' : "Enter a discount less than 80%")
                              setDiscountError(e.target.value && (e.target.value <= 0 || e.target.value >= 100) ? "Enter a valid discount %" : '')
                              var RegExp = /^\d+$/
                              if (!RegExp.test(e.target.value)) {
                                setDiscountError("Enter a valid discount %")
                              }
                            }}
                            required
                          />
                          {discountError && <div className={discountError && "error-text"}>{discountError}</div >}
                        </Form.Group>


                        <Form.Group
                          className="mb-3"
                          controlId="formBasicTitle"
                        >
                          <Form.Label className={startError && "error-text"}>Start Date{" "}
                          </Form.Label>
                          <div className="date-picker-custom">
                            <Form.Control
                              type="date"
                              className={startError && "error-border"}
                              placeholder="Enter Start Date"
                              value={startDate}
                              onChange={(e) => {
                                setStartDate(e.target.value)
                                if (!!expiry) {
                                  if (new Date(e.target.value) > new Date(expiry)) {
                                    setExpiry('')
                                    setExpiryError('')
                                  }
                                }
                                setStartError('')
                              }}
                              min={new Date().toISOString().split('T')[0]} // Set the minimum date to today
                              required
                              pattern="\d{4}-\d{2}-\d{2}"
                            />
                          </div>
                          {startError && <div className={startError && "error-text"}>{startError}</div >}
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="formBasicTitle"
                        >
                          <Form.Label className={expiryError && "error-text"}>End Date{" "}
                          </Form.Label>
                          <div className="date-picker-custom">
                            <Form.Control
                              type="date"
                              className={expiryError && "error-border"}
                              placeholder="Enter End Date"
                              value={expiry}
                              onChange={(e) => {
                                setExpiry(e.target.value)
                                setExpiryError('')
                              }}
                              min={startDate}
                              required
                            />
                          </div>
                          {expiryError && <div className={expiryError && "error-text"}>{expiryError}</div >}
                        </Form.Group>

                        {/* <Form.Group
                          className="mb-3"
                          controlId="formBasicBody"
                        >
                          <Form.Label className={error && "error-text"}>Enter broadcast message
                            {!couponName && <span className="text-sm" style={{ color: "#afb5be" }}>
                              {" "} <i>max 147 characters</i></span>}
                            {!!couponName && <span className="text-sm" style={{ color: "#afb5be" }}>
                              {" "} <i>{147 - couponName.replace(/\s{2,}/g, ' ').length} characters left</i></span>}
                          </Form.Label>
                          <Form.Control as="textarea"
                            className={error && "error-border"}
                            placeholder="Enter broadcast message"
                            minlength="6"
                            style={{ height: '100px' }}
                            maxLength="147"
                            onChange={(e) => {
                              setCouponName(e.target.value.replace(/\s{2,}/g, ' '))
                              setError('')
                            }}
                            value={couponName}
                            required
                          />
                          {error && <div className={error && "error-text"}>{error}</div >}
                        </Form.Group> */}
                      </Form>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <div className="modal-footer">
            <Button
              className="btn-fill"
              type="button"
              variant="link"
              disabled={isLoading}
              onClick={(e) => {
                setShowModal(false)
                setError('')
                setCouponCodeError('')
                setExpiryError('')
                setStartError('')
                setDiscountError('')
                setCouponNameError('')
                setCouponName('')
                setCouponCode('')
                setDiscount("")
                setStartDate("")
                setExpiry("")

              }}
            >
              Close
            </Button>
            <Button
              className="btn-fill"
              type="button"
              variant="primary"
              disabled={isLoading}
              onClick={(e) => createCoupon(e)}
            ><Spinner size="sm" animation="border" hidden={!isLoading} style={{ marginBottom: "2px" }} />{" "}
              {!isLoading ? "Create Coupon" : "Loading"}
            </Button>
          </div>
        </Modal>
        <Row>
          <Col md="12">
            <Card className="mb-0 table-card">
              <Card.Header className="d-flex align-items-center justify-content-between">
                <div>
                  <Card.Title as="h4">Coupons({broadcastCount})</Card.Title>
                </div>
                <Form.Group
                  controlId="exampleForm.ControlSelect1"
                  className="inline-select mr-4"
                ></Form.Group>
                <div
                  className="inline-select mr-4"
                >
                  <Form.Control
                    as="select"
                    onChange={(e) => setStatus(e.target.value)}
                    value={status}
                  >
                    <option selected disabled>
                      Filter By
                    </option>
                    <option value="all">All Status</option>
                    <option value="1">Active</option>
                    <option value="0">Expired</option>
                    <option value="deleted">Deleted</option>
                  </Form.Control>
                </div>
              </Card.Header>
              <Card.Body hidden={coupons.length == 0} className="table-full-width table-responsive p-0">
                <Table striped responsive className="table-hover table-bordered table-type01 mb-0">
                  <thead>
                    <tr>
                      <th style={{ width: 50 }} className="text-center"> {" "}#</th>
                      {/* <th style={{ width: 70 }} className="text-center">ID</th> */}
                      <th style={{ width: 650 }}>Coupon Name</th>
                      <th>Coupon Code</th>
                      <th >Discount</th>
                      <th style={{ width: 140 }}>Start Date</th>
                      <th style={{ width: 140 }}>End Date</th>
                      <th style={{ width: 140 }}>Status</th>
                      <th style={{ width: 120 }}>Action</th>
                    </tr>
                  </thead>
                  <tbody hidden={coupons.length == 0}>
                    {coupons.map((_coupon, index) => (
                      <tr key={index}>
                        <td className="text-center"> {index + 1}</td>
                        {/* <td > {"#" + _coupon.id}</td> */}
                        <td className="text-bold">
                          {_coupon.coupon_name}
                        </td>
                        <td className="text-bold text-justify">
                          <>
                            <CopyToClipboard
                              text={_coupon.coupon_code}
                              onCopy={() => { notify.show('Copied to clipboard', 'success'); }}
                            >
                              <Button >{_coupon.coupon_code}</Button>
                            </CopyToClipboard>
                          </>
                        </td>
                        <td className="text-bold">
                          {_coupon.discount + " %"}
                        </td>
                        <td>
                          {" "}
                          {moment(_coupon.coupon_start_date).format("MM/DD/YYYY")}
                        </td>
                        <td>
                          {" "}
                          {moment(_coupon.coupon_expiry_date).format("MM/DD/YYYY")}
                        </td>
                        <td className="font-weight-bold">
                          {!_coupon.deleted_at && <>   {!!_coupon.status ? <span className="badge badge-active">
                            <FontAwesome className="mr-1" name="check" />
                            Active
                          </span> : <span className="badge badge-rejected">
                            <FontAwesome className="mr-1" name="exclamation" />
                            Expired
                          </span>}</>}
                          {!!_coupon.deleted_at && <span className="badge badge-rejected">
                            <FontAwesome className="mr-1" name="times" />
                            Deleted
                          </span>}
                        </td>
                        <td className="text-center">
                          <Button className="btn-fill" variant="danger" size="sm" disabled={_coupon.id == deleteId || isLoading || !!_coupon.deleted_at} onClick={(e) => {
                            setDeleteId(_coupon.id);
                            setModalDelete(true)
                          }}>{_coupon.id == deleteId ? <Spinner size="sm" animation="border" /> : <FontAwesome className="mr-1" name="trash" />} DELETE</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
              {coupons.length == 0 && <div hidden={coupons.length > 0} className="p-5 d-flex justify-content-center">
                No records found
              </div>}
              <div hidden={coupons.length == 0}>
                <Card.Footer className="text-center d-flex justify-content-end">
                  <Pagination {...paginationConfig} />
                </Card.Footer>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Coupons;
